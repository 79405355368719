/* material input css */

.registration-main-container *,
.registration-main-container :before,
.registration-main-container :after {
    box-sizing: border-box;
}

.registration-main-container .group {
    position: relative;
}
.registration-main-container .group:not(:first-child) {
    margin-top: 23px;
}

.registration-main-container textarea {
    resize: none;
}

.registration-main-container .group input,
.registration-main-container .group select,
.registration-main-container textarea {
    background: none;
    color: #303030;
    font-size: 18px;
    padding: 10px 10px 10px 5px;
    display: block;
    width: 100%;
    border: none;
    border-radius: 0;
    border-bottom: 1px solid #c6c6c6;
}
.registration-main-container .group input:focus,
.registration-main-container .group select:focus,
.registration-main-container textarea:focus {
    outline: none;
}
.registration-main-container .group input:focus ~ label,
.registration-main-container .group input.typed ~ label,
.registration-main-container .group select:focus ~ label,
.registration-main-container .group select.typed ~ label,
.registration-main-container .group textarea:focus ~ label,
.registration-main-container .group textarea.typed ~ label {
    top: -7px;
    font-size: 10px;
    color: #246b47;
}
.registration-main-container .group input:focus ~ .bar:before,
.registration-main-container .group select:focus ~ .bar:before,
.registration-main-container .group textarea:focus ~ .bar:before {
    width: 100%;
}

.registration-main-container .group input.error ~ .bar:before,
.registration-main-container .group select.error ~ .bar:before {
    width: 100%;
    background: #e62942;
}

.registration-main-container .group input[type="password"] {
    letter-spacing: 0.3em;
}

.registration-main-container .group label {
    color: #c6c6c6;
    font-size: 16px;
    font-weight: normal;
    position: absolute;
    pointer-events: none;
    left: 2px;
    top: 16px;
    transition: 300ms ease all;
    color: #b9b9b9;
    font-family: "Montserrat";
    font-size: 14px;
    font-weight: 600;
}

.bar {
    position: relative;
    display: block;
    width: 100%;
}
.bar:before {
    content: "";
    height: 2px;
    width: 0;
    bottom: 0px;
    position: absolute;
    background: #246b47;
    transition: 300ms ease all;
    left: 0%;
}
