input:focus,
textarea:focus {
    outline: none !important;
    box-shadow: inset 0 0 1px rgba(58, 175, 80, 1), 0 0 0px 1px rgba(58, 175, 80, 1) !important;
    /* border-color: #3AAF50 !important;
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(58,175,80,.25); */
}

input[type="checkbox"]:focus {
    box-shadow: none !important;
}

a:hover {
    cursor: pointer;
}

button:focus {
    outline: none !important;
}

textarea {
    resize: none;
    outline: none;
}

.navigationImageActive {
    filter: brightness(2);
}

/* textarea:focus {
  box-shadow: none;
} */

/* .inbox-chatbox-input textarea:focus {
  border: none;
} */

body {
    overflow-y: overlay;
    overflow-x: hidden;
    margin: 0;
    font-size: 100%;
}

/* :focus{
  outline: none;
} */

.ReactModal__Body--open {
    overflow: hidden;
}

/* Top Header CSS starts here */

.top-header {
    background: #3aaf50;
    text-align: center;
    padding: 10px 0;
}

.top-header label {
    font-family: "Montserrat", sans-serif;
    font-size: 16px;
    font-weight: 600;
    color: #fff;
}

.top-header label span {
    text-decoration: underline;
    margin-left: 15px;
}

.top-header img {
    float: right;
    position: relative;
    top: -5px;
    margin-right: 15px;
}

/* Top Header CSS ends here */

/* Menu CSS starts here */

.chat-navigation {
    background: #1d3152;
    padding: 20px 0;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
}

.chat-navigation .chat-menu {
    padding-top: 0px;
}

.chat-navigation .chat-menu li img {
    margin-right: 5px;
}

.chat-navigation .chat-menu li ~ a img {
    width: 22px;
}

.multiline-truncate img {
    width: 17px;
}

.chat-navigation .chat-menu li {
    position: relative;
}

.chat-navigation .chat-menu li label.notification {
    position: absolute;
    top: 0;
    left: 10px;
    font-family: "Montserrat", sans-serif;
    background: #e94d4d;
    font-weight: 700;
    font-size: 8px;
    border-radius: 50%;
    padding: 3px 6px;
    color: #fff;
}

.chat-navigation .chat-menu,
.chat-navigation .chat-right-menu {
    margin: 0;
}

.chat-navigation .chat-menu li {
    list-style: none;
    display: inline-block;
    margin: 0 20px;
}

.chat-navigation .chat-menu li span {
    color: #a7b9c5;
    font-family: "Montserrat", sans-serif;
    font-size: 15px;
    text-transform: uppercase;
    font-weight: 700;
}

.chat-navigation .chat-menu a.active li span {
    color: #ffffff;
}

.chat-navigation .chat-right-menu li {
    list-style: none;
    display: inline-block;
    margin: 0 20px;
}

.chat-navigation .chat-right-menu li button {
    font-family: "Montserrat", sans-serif;
    font-size: 12px;
    font-weight: 700;
    background: #3aaf50;
    border-radius: 20px;
    color: #fff;
    padding: 10px 25px;
    border: 0;
}

.chat-navigation .chat-right-menu li button:focus {
    outline: none;
}

.chat-navigation .chat-right-menu li button:hover {
    cursor: pointer;
    transition: all 0.3s ease;
    opacity: 0.8;
}

.chat-navigation .chat-right-menu li span {
    color: #fff;
    font-family: "Montserrat", sans-serif;
    font-size: 15px;
    text-transform: uppercase;
    font-weight: 700;
}

/* .chat-navigation .chat-right-menu li .css-153wf2s-Switch {
  background-color: #A7B9C5;
  width: 28px;
  height: 20px;
  border-radius: 15px;
} */

.dropdown-show .setting-dropdown ul li .css-153wf2s-Switch {
    background-color: #a7b9c5;
    width: 28px;
    height: 17px;
    border-radius: 15px;
}

.dropdown-show .setting-dropdown ul li .css-19a995p-Switch {
    right: 13px;
}

.toggleswitching {
    display: inline;
}

.dropdown-show .setting-dropdown ul li .soundtoggle {
    justify-self: end;
    margin-left: auto;
}

.dropdown-show .setting-dropdown .soundtoggle .css-jfsaru-Switch {
    background-color: #3aaf50;
}

.dropdown-show .setting-dropdown .soundtoggle .toggleswitching label.css-xjimrv-Switch {
    width: 28px;
    height: 17px;
    vertical-align: text-bottom;
}

.dropdown-show .setting-dropdown .soundtoggle .toggleswitching .css-1w8q89d {
    border-radius: 10px;
    background-color: #3aaf50 !important;
}

.dropdown-show .setting-dropdown .soundtoggle .toggleswitching .css-jfsaru-Switch {
    border-radius: 10px;
    background-color: #3aaf50 !important;
}

.dropdown-show .setting-dropdown .soundtoggle .toggleswitching .css-1v96km2-Switch {
    left: 13px;
}

/* .chat-navigation .chat-right-menu li span.css-1v96km2-Switch,
.chat-navigation .chat-right-menu li span.css-19a995p-Switch {
  background-color: #fff;
  width: 14px;
  height: 14px;
  top: 3px;
} */

/* .chat-navigation .chat-right-menu li span.css-jfsaru-Switch {
  background-color: #3AAF50 !important;
  width: 28px;
  height: 20px;
  border-radius: 15px;
} */

.profile-setting-btn {
    border-radius: 50%;
    width: 40px;
    height: 40px;
    display: inline-block;
    background-repeat: no-repeat;
    background-position: center center;
    background-size: contain;
    margin: 0 15px;
    position: relative;
    top: 10px;
}

/* .chat-navigation .chat-right-menu li label {
  display: inline-block;
  margin: 0 15px;
} */

.chat-right-menu img {
    margin: 0 5px;
    width: 14px;
}

.setting-dropdown ul {
    width: 240px;
    margin: 0;
    padding: 0;
    background: #fff;
    border-radius: 5px;
    box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.16), 0 2px 10px 0 rgba(0, 0, 0, 0.12);
}

.setting-dropdown ul li:first-child {
    list-style: none;
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
    background: #f7f9fa;
    padding: 20px;
    display: block;
}

.setting-dropdown ul li {
    padding: 10px 20px;
    border-bottom: 1px solid #dde4e7;
    list-style: none;
    margin: 0 !important;
    width: 100%;
}

.setting-dropdown ul li:not(:first-child):hover {
    background: #f3f4f5;
    cursor: pointer;
    transition: all 0.3s;
}

.setting-dropdown ul li h3 {
    color: #1d3152;
    font-family: "Montserrat", sans-serif;
    font-size: 16px;
    text-transform: capitalize;
    font-weight: 500;
    display: inline-block;
}

.setting-dropdown ul li a {
    color: #1d3152;
    font-family: "Montserrat", sans-serif;
    font-size: 16px;
    text-transform: capitalize;
    font-weight: 500;
    display: inline-block;
    text-decoration: none;
}

.setting-dropdown ul li a:hover {
    color: #000;
    text-decoration: none;
    transition: all 0.3s;
}

.setting-dropdown ul li p {
    color: #638ccf;
    font-family: "Muli", sans-serif;
    font-size: 12px;
    font-weight: 700;
    margin: 0;
}

.setting-dropdown ul li h4 {
    color: #1d3152;
    font-family: "Muli", sans-serif;
    font-size: 16px;
    font-weight: 700;
    margin: 0;
}

.disabled {
    pointer-events: none;
    opacity: 0.6;
}

.chat-right-menu li {
    position: relative;
}

.chat-right-menu li:last-child .setting-dropdown {
    position: absolute;
    right: 0;
    border-radius: 5px;
    top: 35px;
}

/* Menu CSS ends here */

/* Chats Aside bar css starts here */

.main-content .chats-aside-content {
    width: 30%;
    float: left;
    height: 100vh;
    /* margin-top: 89px; */
    border-right: 1px solid #d2dade;
}

.main-content .chats-aside-content .search-bar {
    padding: 15px;
    position: relative;
}

.main-content .chats-aside-content .search-bar input {
    border: 1px solid #d2dade;
    border-radius: 5px;
    width: 100%;
    padding: 12px 12px 12px 40px;
    background: url(./assets/Newimages/search-icon.svg);
    background-repeat: no-repeat;
    background-size: 16px;
    background-position: 10px;
}

/* .main-content .chats-aside-content .search-bar input:focus {
  border: 1px solid #3D4E75;
} */

.main-content .chats-aside-content .search-bar input::placeholder {
    font-size: 11px;
    font-weight: 400;
    font-family: "Muli", sans-serif;
    color: #344c60;
}

.search-suggesion {
    padding: 20px 0;
    position: absolute;
    background: #fff;
    width: 93%;
    border: 1px solid #ccc;
    border-radius: 5px;
    display: none;
    z-index: 1;
}

.main-content .chats-aside-content .search-bar input:focus ~ .search-suggesion {
    display: block;
}

.search-suggesion ul {
    margin: 0;
    padding: 0;
}

.search-suggesion ul li:first-child span {
    font-family: "Montserrat", sans-serif;
    border: none;
    color: #3aaf50;
    font-size: 10px;
    font-weight: 600;
    padding: 0 !important;
}

.search-suggesion ul li {
    list-style: none;
    margin-bottom: 5px;
    padding: 0 20px;
    padding-top: 5px;
    padding-bottom: 5px;
}

.search-suggesion ul li:not(:first-child):hover {
    background: #dee4e7;
    transition: all 0.3s;
}

.search-suggesion ul li span {
    background: #f9fafd;
    padding: 7.5px 20px;
    border: 1px solid #a7b9c5;
    border-radius: 5px;
    font-size: 12px;
    font-weight: 400;
    font-family: "Muli", sans-serif;
    color: #1d3152;
    display: inline-block;
    letter-spacing: 1px;
}

.tab-head {
    border-bottom: 20px solid #dde4e7;
    margin-top: 10px;
}

.tab-head h4 {
    font-family: "Montserrat", sans-serif;
    color: #3d4e75;
    font-size: 14px;
    font-weight: 400;
    text-align: center;
    padding: 10px 0;
    margin: 0;
    cursor: pointer;
    position: relative;
}

.tab-head h4.active::before {
    content: "";
    /* border-bottom: 7px solid #1D3152; */
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 7px;
    background: #1d3152;
    border-radius: 20px;
}

.tab-head h4.active span {
    margin-left: 5px;
}

.tab-middle-content {
    margin-top: 60px;
}

.tab-middle-content h3 {
    color: #718c9e;
    font-family: "Montserrat", sans-serif;
    font-size: 20px;
    font-weight: 600;
    text-align: center;
}

.tab-middle-content p {
    color: #344c60;
    font-family: "Muli", sans-serif;
    font-size: 16px;
    font-weight: 600;
    text-align: center;
}

.tab-middle-content p span {
    color: #3aaf50;
}

.tab-middle-content img {
    display: block;
    margin: 0 auto;
}

.chats-aside-content .tab-content {
    padding: 15px;
    height: calc(97.5vh - 14.25rem);
    /* height: 65vh; */
    overflow-y: auto;
}

.chats-aside-content .tab-content .tab-middle-boxes {
    border: 1px solid #d2dade;
    background: #f7f9fa;
    padding: 10px 15px;
    border-radius: 5px;
    cursor: pointer;
}

.tab-middle-boxes {
    margin-bottom: 15px;
    word-break: break-all;
}

.tab-middle-boxes.active {
    border: 1px solid #3aaf50 !important;
    background-color: #ebf7ed !important;
}

.tab-middle-boxes.online {
    position: relative;
}

.tab-middle-boxes.online:before {
    content: "";
    position: absolute;
    top: 5px;
    left: 5px;
    border-radius: 50%;
    width: 10px;
    height: 10px;
    background: #3aaf50;
}

.tab-middle-boxes .tab-box-img {
    /* display: inline-block; */
    display: flex;
    align-items: center;
    justify-content: center;
    background: #1d3152;
    border-radius: 50%;
    /* padding: 7px 5px; */
    width: 35px;
    height: 35px;
}

.tab-middle-boxes .tab-box-img.online {
    background: #3aaf50 !important;
}

.tab-middle-boxes .tab-box-img p {
    font-family: "Montserrat", sans-serif;
    font-weight: 700;
    font-size: 12px;
    color: #fff;
    text-transform: uppercase;
    text-align: center;
    margin: 0;
}

.tab-middle-boxes .tab-box-time p {
    font-family: "Muli", sans-serif;
    font-weight: 400;
    font-size: 10px;
    color: #344c60;
    text-align: right;
    margin: 0;
}

.tab-middle-boxes .tab-box-name {
    padding-left: 15px;
}

.tab-box-name h4 {
    font-family: "Muli", sans-serif;
    font-weight: 600;
    font-size: 14px;
    color: #344c60;
    margin: 0;
}

.tab-box-name p {
    font-family: "Muli", sans-serif;
    font-weight: 400;
    font-size: 14px;
    color: #344c60;
    margin-top: 2px;
    margin-bottom: 0;
    word-break: break-word;
}

.tab-box-name ul {
    padding: 0;
    margin-bottom: 0;
    margin-top: 22px;
}

.tab-box-name ul li {
    color: #a7b9c5;
    font-family: "Muli", sans-serif;
    font-weight: 400;
    font-size: 8.4px;
    list-style: none;
    display: inline-block;
    border: 1px solid #a7b9c5;
    border-radius: 5px;
    padding: 5px 10px;
    margin-right: 7px;
}

/* Chats Aside bar css ends here */

/* Contacts chat Asidebar starts here */

.main-content .contact-aside-content {
    width: 20%;
    height: calc(100vh - 80px);
    float: left;
    border-right: 1px solid #d2dade;

    /* margin-top: 89px;
  position: fixed;
  background: #fff; */
    overflow-y: auto;
}

.main-content .contact-aside-content .active-list li:first-child,
.main-content .contact-aside-content .inactive-list li:first-child,
.main-content .contact-aside-content .pending-list li:first-child {
    border-bottom: 1px solid #d2dade;
    padding-top: 20px;
    padding-bottom: 28px;
}

.main-content .contact-aside-content .active-list li:first-child span,
.main-content .contact-aside-content .inactive-list li:first-child span,
.main-content .contact-aside-content .pending-list li:first-child span {
    font-family: "Montserrat", sans-serif;
    color: #1d3152;
    font-size: 16px;
    font-weight: 700;
}

.main-content .contact-aside-content .active-list {
    padding: 0;
    margin: 0;
}

.main-content .contact-aside-content .inactive-list {
    padding: 0;
    margin-top: 40px;
}

.main-content .contact-aside-content .pending-list {
    padding: 0;
    margin-top: 40px;
}

.main-content .contact-aside-content .active-list li,
.main-content .contact-aside-content .inactive-list li,
.main-content .contact-aside-content .pending-list li {
    list-style: none;
    font-family: "Montserrat", sans-serif;
    color: #a7b9c5;
    font-size: 14px;
    font-weight: 600;
    padding: 16px 35px;
}

.active-list input[type="checkbox"],
.inactive-list input[type="checkbox"],
.pending-list input[type="checkbox"] {
    display: none;
}

/****************active and inactive checkbox starts***************/

.contact-aside-content .active-list li:first-child .active-checkbox + label,
.contact-aside-content .inactive-list li:first-child .inactive-checkbox + label,
.contact-aside-content .pending-list li:first-child .pending-checkbox + label {
    border: 2px solid #707070;
    padding: 6px;
    border-radius: 3px;
    margin-right: 20px;
    display: inline-block;
    position: relative;
}

.contact-aside-content .active-list li:first-child .active-checkbox:checked + label,
.contact-aside-content .inactive-list li:first-child .inactive-checkbox:checked + label,
.contact-aside-content .pending-list li:first-child .pending-checkbox:checked + label {
    border: 2px solid #707070;
    color: #99a1a7;
}

.contact-aside-content .active-list li:first-child .active-checkbox:checked + label:after,
.contact-aside-content .inactive-list li:first-child .inactive-checkbox:checked + label:after,
.contact-aside-content .pending-list li:first-child .pending-checkbox:checked + label:after {
    content: "";
    position: absolute;
    top: 1px;
    left: 1px;
    color: #99a1a7;
    width: 80%;
    height: 80%;
    border-radius: 2px;
    background: #3aaf50;
}

/***************active and inactive checkbox ends******************/

/***************active item starts********************/

.contact-aside-content .active-list li:not(:first-child) {
    padding-left: 45px;
}

.contact-aside-content .inactive-list li:not(:first-child) {
    padding-left: 45px;
}

.contact-aside-content .pending-list li:not(:first-child) {
    padding-left: 45px;
}

.contact-aside-content .active-list li:not(:first-child) .active-items + label {
    border: 2px solid #707070;
    padding: 6px;
    border-radius: 3px;
    margin-right: 20px;
    display: inline-block;
    position: relative;
}

.contact-aside-content .active-list li:not(:first-child) .active-items:checked + label {
    background-color: #1d3152;
    border: 2px solid #1d3152;
    color: #99a1a7;
}

.contact-aside-content .active-list li:not(:first-child) .active-items:checked + label:after {
    content: "\2714";
    font-size: 12px;
    position: absolute;
    top: -2px;
    left: 0px;
    color: #fff;
}

.contact-aside-content .inactive-list li:not(:first-child) .active-items + label {
    border: 2px solid #707070;
    padding: 6px;
    border-radius: 3px;
    margin-right: 20px;
    display: inline-block;
    position: relative;
}

.contact-aside-content .inactive-list li:not(:first-child) .active-items:checked + label {
    background-color: #1d3152;
    border: 2px solid #1d3152;
    color: #99a1a7;
}

.contact-aside-content .inactive-list li:not(:first-child) .active-items:checked + label:after {
    content: "\2714";
    font-size: 12px;
    position: absolute;
    top: -2px;
    left: 0px;
    color: #fff;
}

.contact-aside-content .pending-list li:not(:first-child) .active-items + label {
    border: 2px solid #707070;
    padding: 6px;
    border-radius: 3px;
    margin-right: 20px;
    display: inline-block;
    position: relative;
}

.width-100 {
    width: 100%;
}

.contact-aside-content .pending-list li:not(:first-child) .active-items:checked + label {
    background-color: #1d3152;
    border: 2px solid #1d3152;
    color: #99a1a7;
}

.contact-aside-content .pending-list li:not(:first-child) .active-items:checked + label:after {
    content: "\2714";
    font-size: 12px;
    position: absolute;
    top: -2px;
    left: 0px;
    color: #fff;
}

/***************active item ends******************/

.main-content .contact-aside-content .inactive-list .count,
.main-content .contact-aside-content .active-list .count,
.main-content .contact-aside-content .pending-list .count {
    float: right;
}

/* Contacts chat asidebar ends here */

/* Chats page Article Section starts here */

.main-content .chats-article-content {
    width: 70%;
    float: left;
    height: 100vh;
    transition: width 1.5s;
    /* height: calc(100% - 233rem); */
    /*  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;*/
}

.no-chat-backgroud {
}

.article-middle-chat {
    border-left: 1px solid #d2dade;
}

.article-middle-content {
    margin-bottom: 200px;
}

.article-middle-content h3 {
    color: #344c60;
    font-family: "Montserrat", sans-serif;
    font-size: 30px;
    font-weight: 600;
    text-align: center;
    margin: 0;
}

.article-middle-content p {
    color: #a7b9c5;
    font-family: "Muli", sans-serif;
    font-size: 11px;
    font-weight: 400;
    text-align: center;
}

.middle-chat-header {
    /*border-bottom: 1px solid #D2DADE;*/
    padding: 26px;
}

.middle-chat-header .middle-chat-header-threedots span {
    color: #a7b9c5;
    transform: rotate(90deg);
    font-size: 30px;
    font-weight: 700;
    line-height: 0;
    display: inline-block;
    cursor: pointer;
    margin-left: 10px;
}

.middle-chat-header {
    position: relative;
}

.middle-chat-header-threedots-dropdown ul {
    margin: 0;
    padding: 0;
}

.middle-chat-header-threedots {
    position: relative;
    width: 50%;
}

.middle-chat-header-threedots-dropdown {
    width: 200px;
    position: absolute;
    left: 0;
    top: 27px;
    /* top: 100%; */
    z-index: 1;
    background: #fff;
    border-radius: 5px;
    box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.16), 0 2px 10px 0 rgba(0, 0, 0, 0.12);
    padding: 12px 0;
}

/* .server-notification-indigator p {
  position: relative;
  margin-left: 10px;

} */
/* .server-notification-indigator p:after {
  content: '  ';
  position: absolute;
  left: 0;
  top: 2px;
  background: #1D3152;
  width: 2px;
  height: 13px;
  margin-left: -10px;
} */
.middle-chat-header-threedots-dropdown ul li label img {
    float: left;
    /* margin-right: 5px; */
    margin-top: 4px;
    width: 23px;
}

.middle-chat-header-threedots-dropdown ul li {
    list-style: none;
    padding: 10px 12px;
}

.middle-chat-header-threedots-dropdown ul li:hover {
    background: #f3f4f5;
    cursor: pointer;
    transition: all 0.3s;
}

/* .middle-chat-header-threedots-dropdown ul li:not(:last-child){
  margin-bottom: 20px;
} */

.middle-chat-header .inbox-chatbox-chat-num .circleLeftArrow {
    background-color: #0f1e46;
    border-radius: 50%;
    float: right;
    line-height: 1;
    width: 30px;
    height: 30px;
    padding: 5px;
    text-align: center;
    cursor: pointer;
}

.middle-chat-header img {
    transform: rotate(0);
    transition: transform 1.5s;
}

.middle-chat-header img.left-arrow {
    transform: rotate(180deg);
    transition: transform 1.5s;
}

.middle-chat-header-threedots-dropdown label {
    font-family: "Montserrat", sans-serif;
    color: #1d3152;
    font-size: 12px;
    font-weight: 600;
    padding-left: 3px;
}

.middle-chat-content {
    border-top: 1px solid #d2dade;
    border-bottom: 1px solid #d2dade;
    /*border-left: 1px solid #D2DADE;*/
}

.middle-chat-header h3 {
    color: #3d4e75;
    font-family: "Montserrat", sans-serif;
    font-weight: 600;
    font-size: 20px;
    padding: 0 25px 0 0;
    margin: 0;
    display: inline-block;
}

.middle-chat-header h3:hover {
    transition: all 0.3s linear;
    text-decoration: underline;
    cursor: pointer;
}

.middle-chat-header .inbox-chatbox-chat-num {
    float: right;
}

.middle-chat-header p {
    float: right;
    color: #8a9198;
    font-family: "Montserrat", sans-serif;
    font-weight: 400;
    font-size: 18px;
    margin: 0;
}

.middle-chat-time {
    margin-top: 60px;
    margin-bottom: 30px;
}

.middle-chat-time p {
    color: #a7b9c5;
    font-family: "Muli", sans-serif;
    font-size: 10px;
    font-weight: 400;
    text-align: center;
    margin: 0;
}

.message-sender .message-sender-img {
    display: inline-block;
    background: #3aaf50;
    border-radius: 50%;
    padding: 15px;
}

.message-sender .message-sender-img p {
    font-family: "Montserrat", sans-serif;
    font-weight: 700;
    font-size: 14px;
    color: #fff;
    text-transform: uppercase;
    text-align: center;
    margin: 0;
}

.middle-chat-content {
    padding: 20px;
    height: calc(100% - 13rem);
    overflow-y: auto;
}

.message-sender-chat {
    max-width: 66.66%;
    /* display: inline-block; */
    text-align: right;
    float: right;
    max-width: 66.66%;
}

.sendaction {
    display: flex;
    padding: 0px 20px 20px 30px;
    align-items: center;
    justify-content: space-between;
}

.sendaction .onBoard p {
    font-size: 13px !important;
}

.sendaction .onBoard {
    /* display: flex; */
    display: flex;
    align-items: center;
}

.message-sender-chat .new-inbox-chatbox-sent-message-wrapper .inbox-chatbox-sent-message img {
    max-width: 500px;
}

.message-sender-chat .new-inbox-chatbox-sent-message-wrapper {
    padding: 14px 18px;
    color: #344c60;
    font-family: "Montserrat";
    font-size: 15px;
    font-weight: 500;
    margin: 0;
    background: #ebf7ed;
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 0;
    word-break: break-all;
    display: inline-block;
}

.message-sender-chat label {
    color: #a7b9c5;
    font-family: "Muli", sans-serif;
    font-size: 10px;
    font-weight: 400;
    text-align: right;
    display: block;
    margin-top: 5px;
}

.message-server-chat label {
    color: #a7b9c5;
    font-family: "Muli", sans-serif;
    font-size: 10px;
    font-weight: 400;
    text-align: center;
    display: block;
    margin-top: 2px;
}

.message-receiver {
    margin-bottom: 15px;
}

.message-receiver .message-receiver-img {
    display: inline-block;
    background: #1d3152;
    border-radius: 50%;
    padding: 15px;
}

.message-receiver .message-receiver-img p {
    font-family: "Montserrat", sans-serif;
    font-weight: 700;
    font-size: 14px;
    color: #fff;
    text-transform: uppercase;
    text-align: center;
    margin: 0;
}

.message-receiver-chat {
    max-width: 66.66%;
    display: inline-block;
    max-width: 66.66%;
}

.message-receiver-chat .new-inbox-chatbox-received-message-wrapper {
    padding: 14px 18px;
    color: #344c60;
    font-family: "Muli", sans-serif;
    font-size: 16px;
    font-weight: 800;
    margin: 0;
    background: #dee4e7;
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 5px;
    display: inline-block;
    word-break: break-all;
}

.message-receiver-chat label {
    color: #a7b9c5;
    font-family: "Muli", sans-serif;
    font-size: 10px;
    font-weight: 400;
    text-align: left;
    display: block;
    margin-top: 5px;
}

.middle-chat-form {
    padding: 20px;
    height: 200px;
    /* position:fixed;
  width: 100%;
  bottom: 0; */
}

.middle-chat-form textarea {
    padding: 20px 15px;
    border: 1px solid #d8dbdd;
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
    width: 100%;
    font-family: "Muli", sans-serif;
    font-size: 14px;
    font-weight: 400;
    height: 70px;
}

.middle-chat-form textarea::placeholder {
    color: #c6d0d5;
    font-family: "Muli", sans-serif;
    font-size: 14px;
    font-weight: 400;
}

.emojisection {
    /*padding: 10px;*/
    border-left: 1px solid #d8dbdd;
    border-right: 1px solid #d8dbdd;
    border-bottom: 1px solid #d8dbdd;
    position: relative;
    top: -5px;
}

.emojisection ul {
    padding: 0;
    margin: 0;
}

.emojisection ul li {
    list-style: none;
    display: inline-block;
    padding: 10px;
    /* position: relative; */
}

.min-width-30 {
    min-width: 30px;
}

.custom-tooltip-theme {
    color: #000000 !important;
    background-color: #f6f7f8 !important;
    font-size: 10px;
    font-weight: 500;
    opacity: 100;
    box-shadow: 0px 4px 7px 1px rgb(212 212 212 / 15%);
}

.custom-tooltip-theme::after {
    border-top-color: #f6f7f8 !important;
    border-top-style: solid !important;
    border-top-width: 8px !important;
}

/* .emojisection.messagebox-emoji-section li:nth-child(7){
  width: 120px;
}
.emojisection.messagebox-emoji-section li:nth-child(7){
  width: 120px;
} */
/* 
.emojisection  ul li:nth-child(7){
  width: 120px;
} */
.width-120 {
    width: 120px !important;
}

.emojisection ul li:not(:last-child):hover {
    background: #f2f4f5;
    /* position: relative;
  top: 1px; */
}

/*
.emojisection ul li:last-child {
  float: right;
  margin-right: 0;
  padding-bottom: 0;
}
*/
.tooltip {
    /* position: relative; */
}

.tooltip .tooltiptext {
    visibility: hidden;
    width: 120px;
    background-color: black;
    color: #fff;
    text-align: center;
    border-radius: 6px;
    padding: 5px 0;

    /* Position the tooltip */
    position: absolute;
    z-index: 1;
    bottom: 100%;
    left: 50%;
    margin-left: -60px;
}

.tooltip:hover .tooltiptext {
    visibility: visible;
}

.tooltip .tooltipimage {
    visibility: hidden;
    width: 200px;
    /* background-color: black; */
    color: #fff;
    text-align: center;
    border-radius: 6px;
    padding: 5px 0;
    position: absolute;
    z-index: 1;
    bottom: 100%;
    left: 50%;
    margin-left: -160px;
}

.tooltip:hover .tooltipimage {
    visibility: visible;
}

.emojisection ul li span {
    color: #a7b9c5;
    font-family: "Muli", sans-serif;
    font-size: 10px;
    font-weight: 400;
}

.form-btn {
    text-align: right;
    margin-top: 15px;
}

.chat-form-btn {
    border-radius: 22px;
    background: #c6d0d5;
    border: 0;
    color: #fff;
    font-family: "Muli", sans-serif;
    font-size: 12px;
    font-weight: 700;
    margin: 0 5px;
    padding: 10px 20px;
    cursor: pointer;
    outline: none;
}

.campain-cancel-button {
    border-radius: 22px;
    background: #ffffff;
    border: 0;
    color: #1d3152;
    font-family: "Muli", sans-serif;
    font-size: 12px;
    font-weight: 700;
    margin: 0 5px;
    padding: 10px 20px;
    cursor: pointer;
    outline: none;
}

.valid {
    background: #3aaf50 !important;
}

.chat-form-btn:hover {
    background: #3aaf50;
    transition: all 0.3s;
}

/* Chats page Article Section css ends here */

/* Contacts page Article Section starts here */

.main-content .contacts-article-content {
    width: 80%;
    height: calc(100vh - 80px);
    overflow: hidden;
    float: left;
    /* height: 100vh; */
    transition: width 1.5s;
}

.main-content .contact-articles-reduces {
    width: 60%;
    transition: width 1.5s;
}

.main-content .chats-article-reduces {
    width: 50%;
    transition: width 1.5s;
}

.contacts-article-middle-content h3 {
    color: #344c60;
    font-family: "Montserrat", sans-serif;
    font-size: 30px;
    font-weight: 600;
    text-align: center;
    margin: 0;
}

.contacts-article-middle-content {
    margin-top: 40px;
}

.contacts-article-middle-content img {
    display: block;
    margin: 20px auto;
}

.fileuploadpercentage {
    text-align: center;
    padding: 20px 0;
}

.action-btn {
    background: #3aaf50;
    border: 0;
    border-radius: 20px;
    font-size: 12px;
    font-weight: 700;
    color: #fff;
    opacity: 1;
}

.action-btn:hover {
    cursor: pointer;
}

.action-btn:focus {
    outline: none;
}

.action-button {
    background: #3aaf50;
    border: 1px solid #3aaf50;
    border-radius: 20px;
    padding: 4px 15px 8px;
    display: inline-block;
}

.action-button:hover {
    transition: all 0.3s ease;
    opacity: 0.8;
    cursor: pointer;
}

.clear-button {
    background: #cbd4cd;
    border: 1px solid #cbd4cd;
    border-radius: 20px;
    padding: 4px 15px 8px;
    display: inline-block;
    margin-left: 10px;
}

.clear-btn {
    background: #cbd4cd;
    border: 0;
    border-radius: 20px;
    font-size: 12px;
    font-weight: 700;
    color: #fff;
    opacity: 1;
}

.clear-btn:focus {
    outline: none;
}

/* .dropdown-show:not(.hide) {
  display: block;
}

.dropdown-show {
  background-color: red;
  transform: translate(0, 0);
  transition: transform .35s ease;
}

.dropdown-show.hide{
  transform: translate(0, -100%);
  transition: transform .35s ease;
} */

.action-button-dropdown ul {
    padding: 0;
    margin: 0;
}

.action-button-dropdown {
    position: absolute;
    background: #fff;
    border-radius: 5px;
    box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.16), 0 2px 10px 0 rgba(0, 0, 0, 0.12);
}

.action-button-dropdown ul li {
    padding: 15px;
    list-style: none;
}

.action-button-dropdown ul li span {
    color: #1d3152;
    font-family: "Montserrat", sans-serif;
    font-size: 12px;
    font-weight: 600;
    padding: 0 10px;
    display: inline-block;
}

.action-button-dropdown ul li:hover span {
    color: #fff;
}

.action-button-dropdown ul .delete-button:hover {
    background: #e94d4d !important;
    cursor: pointer;
}

.action-button-dropdown ul li:hover {
    background: #1d3152;
    cursor: pointer;
}

.action-button-dropdown ul li img {
    width: 17px;
    filter: contrast(0);
}

.action-button-dropdown ul li:hover img {
    filter: brightness(100);
}

/*.action-btn:hover{
  background: transparent;
  border: 1px solid #76C785;
  color: #76C785;
  transition: all .3s;
}*/

.contacts-btn {
    font-family: "Montserrat", sans-serif;
    font-size: 12px;
    font-weight: 700;
    background: #1d3152;
    border: 1px solid #1d3152;
    color: #fff;
    border-radius: 20px;
    padding: 10px 30px;
    text-decoration: none;
    margin: 0 5px;
    outline: none;
}

.contacts-btn:hover {
    color: #1d3152;
    text-decoration: none;
    border: 1px solid #1d3152;
    background: transparent;
    transition: all 0.3s;
}

.contact-btn {
    text-align: center;
    margin-top: 40px;
}

.contacts-article-header {
    padding: 20px;
    border: 1px solid #d2dade;
}

.contacts-article-header .contacts-count {
    float: right;
}

.contacts-article-header .contacts-count label {
    font-family: "Muli", sans-serif;
    font-weight: 700;
    font-size: 16px;
    color: #1d3152;
}

.contactus-searchbar {
    background: #dde4e7;
    padding: 10px 15px;
}

.contactus-searchbar input {
    width: 100%;
    border-radius: 5px;
    border: 0;
    padding: 8px 35px;
    background: #fff url(./assets/Newimages/search-icon.svg);
    background-size: 16px;
    background-repeat: no-repeat;
    background-position: 10px;
}

.contactus-searchbar form {
    position: relative;
}

.contactus-searchbar input:placeholder {
    font-family: "Muli", sans-serif;
    font-weight: 400;
    font-size: 14px;
    color: #344c60;
}

/*.contact-list-table{
  border-bottom: 1px solid #D2DADE;
}*/

/*.contact-list-table table{
  margin-left: 80px;
}*/

.contact-list-table table {
    width: 100%;
    border-collapse: collapse;
    margin-bottom: 50px;
}

.contact-list-table table th {
    font-family: "Muli", sans-serif;
    font-weight: 700;
    font-size: 12px;
    color: #1d3152;
    padding: 15px 20px;
    border-bottom: 0px solid #d2dade;
    text-align: left;
}

.contact-list-table table td {
    font-family: "Muli", sans-serif;
    font-weight: 400;
    font-size: 12px;
    color: #1d3152;
    padding: 15px 20px;
    border-bottom: 1px solid #d2dade;
}

.contact-list-table table td:last-child {
    /* color: #3AAF50; */
    color: #1d3152;
    font-family: "Muli", sans-serif;
    /* font-weight: 700; */
    font-size: 12px;
    cursor: pointer;
}

.contact-list-table table tr:hover {
    background: #e9f6ed;
}

.contact-list-table table tr.tr-checked {
    background: #e9f6ed;
}

.contact-list-table table tr td {
    border-top: 1px solid #d8d8d8;
    border-bottom: 1px solid #d8d8d8;
}

.contact-list-table table tr.tr-checked td {
    border-top: 1px solid #3aaf50;
    border-bottom: 1px solid #3aaf50;
}

.contact-list-table table tr.tr-checked-selected td {
    border-top: 1px solid#3AAF50;
    border-bottom: 1px solid #3aaf50;
}

.contact-list-table table tr.tr-checked-selected {
    background: #ccecd6;
}

.contact-list-table table tr.tr-show-details {
    background: #eeeff0;
}

.checkbox-wrapper input {
    display: none;
}

.checkbox-wrapper input:checked + label.checkmark {
    background-color: #1d3152;
    border: 2px solid #1d3152;
    color: #99a1a7;
}

.checkbox-wrapper input + label.checkmark {
    border: 2px solid #707070;
    padding: 6px;
    border-radius: 3px;
    margin-right: 20px;
    display: inline-block;
    position: relative;
}

.checkbox-wrapper input:checked + label.checkmark:after {
    content: "\2714";
    font-size: 12px;
    position: absolute;
    top: -2px;
    left: 0px;
    color: #fff;
}

.contact-list-table ul {
    padding: 0;
    margin: 0;
}

.contact-list-table ul li {
    list-style: none;
    display: inline-block;
}

.contact-list-table ul li span {
    background: #f9fafd;
    border-radius: 5px;
    border: 1px solid #a7b9c5;
    margin-right: 5px;
    padding: 5px 10px;
    font-family: "Muli", sans-serif;
    font-weight: 400;
    font-size: 12px;
    color: #1d3152;
    display: inline-block;
    margin-bottom: 5px;
}

.contact-list-checkbox input {
    display: none;
}

.contact-list-checkbox .active-items:checked + label {
    background-color: #1d3152;
    border: 2px solid #1d3152;
    color: #99a1a7;
}

.contact-list-checkbox .active-items + label {
    border: 2px solid transparent;
    padding: 6px;
    border-radius: 3px;
    margin-right: 20px;
    display: inline-block;
    position: relative;
}

.contact-list-table table tr:hover .contact-list-checkbox .active-items + label {
    background: #fff;
}

.contact-list-table table tr:hover .contact-list-checkbox .active-items:checked + label {
    background: #1d3152;
}

.contact-list-checkbox .active-items:checked + label:after {
    content: "\2714";
    font-size: 12px;
    position: absolute;
    top: -2px;
    left: 0px;
    color: #fff;
}

/* Contacts page Article Section ends here */

/* Contact page Sidebar section starts here */

.main-content .contacts-sidebar-content {
    /* width: 0%; */
    background: #fff;
    /* height: 86.5vh; */
    height: calc(100vh - 5.5625rem);
    position: absolute;
    top: 0;
    /* right: 0; */
    border-left: 1px solid #d2dade;
    border-bottom: 1px solid #d2dade;
    overflow-y: auto;
    /* margin-right: 5px; */
    transition: width 1.5s;
}

.main-content .contacts-sidebar-open {
    transition: right 1.5s;
    right: 0;
    width: 20%;
}

.main-content .contacts-sidebar-close {
    right: -20%;
    transition: right 1.5s;
    width: 20%;
}

.sidebar-name-pic {
    text-align: center;
}

.profile-sidebar-avatar-container {
    border-bottom: 1px solid #d2dade;
}

.sidebar-name-pic span {
    background: #1d3152;
    border-radius: 50%;
    font-family: "Montserrat", sans-serif;
    font-weight: 700;
    font-size: 16px;
    color: #fff;
    text-transform: capitalize;
    padding: 12px 10px;
}

.profile-info-section {
    background: #f9fafd;
    padding: 30px;
    border-bottom: 1px solid #d2dade;
}

.sidebar-name-pic label {
    font-family: "Montserrat";
    font-weight: 600;
    font-size: 16px;
    color: #1d3152;
    display: block;
    margin: 20px 0;
}

.profile-phone-email {
    /* border-bottom: 1px solid #D2DADE; */
    /* padding-bottom: 15px; */
}

.profile-phone-email input {
    border-radius: 5px;
    padding: 4px 10px;
    width: 100%;
    border: 1px solid #3aaf50;
    font-family: "Muli", sans-serif;
    color: #1d3152;
    font-size: 16px;
    font-weight: 600;
}

.profile-gender-birth select {
    border-radius: 5px;
    padding: 4px 10px;
    width: 100%;
    border: 1px solid #3aaf50;
    font-family: "Muli", sans-serif;
    color: #1d3152;
    font-size: 16px;
    font-weight: 600;
    margin-top: 10px;
}

.profile-gender-birth input {
    border-radius: 5px;
    padding: 4px 10px;
    width: 100%;
    border: 1px solid #3aaf50;
    font-family: "Muli", sans-serif;
    color: #1d3152;
    font-size: 16px;
    font-weight: 600;
    margin-top: 10px;
}

.profile-phone-email input.error-red,
.profile-gender-birth input.error-red,
.profile-gender-birth select.error-red {
    border: 1px solid #dc3545;
}

.button-More {
    padding: 12px;
    display: block;
    text-align: right;
    color: #c6d0d5;
    font-family: "Muli", sans-serif;
    font-size: 12px;
    font-weight: 600;
    text-transform: lowercase;
}

.button-More img {
    transform: rotate(270deg) !important;
    transition: transform 1.5s !important;
    margin-left: 5px;
}

.button-More:hover {
    cursor: pointer;
    color: #1d3152;
}

.button-Less {
    padding: 12px;
    display: block;
    text-align: right;
    color: #c6d0d5;
    font-family: "Muli", sans-serif;
    font-size: 12px;
    font-weight: 600;
    text-transform: lowercase;
}

.button-Less img {
    transform: rotate(90deg) !important;
    transition: transform 1.5s !important;
    margin-left: 5px;
}

.button-Less:hover {
    cursor: pointer;
    color: #1d3152;
}

.profile-phone-email ul {
    margin: 0;
    padding: 0;
}

.profile-phone-email ul li {
    list-style: none;
    margin-top: 15px;
}

.profile-phone-email ul li span {
    font-family: "Muli", sans-serif;
    /* font-weight: 600; */
    font-size: 16px;
    color: #a7b9c5;
    display: block;
}

.profile-phone-email ul li span img {
    float: left;
    margin-right: 10px;
    /* margin-bottom: 20px; */
    width: 20px;
    height: 20px;
}

.profile-phone-email ul li label {
    font-family: "Muli", sans-serif;
    /* font-weight: 600; */
    font-size: 16px;
    color: #1d3152;
    margin-top: 2px;
    display: block;
    word-break: break-all;
    padding-left: 30px;
}

.profile-gender-birth {
    /* border-bottom: 1px solid #D2DADE; */
    /* padding-bottom: 15px; */
    padding-left: 25px;
    overflow: hidden;
    height: 0;
    transition: height 0.5s;
}

/* .profile-gender-birth-slide-less{
  height: 0;
  padding: 0;
  border: 0;
  transition: height 0.5s;
} */

.profile-gender-birth-slide-more {
    height: 200px;
    padding-bottom: 15px;
    transition: height 0.5s;
}

.profile-gender-birth ul {
    margin: 0;
    padding: 0;
}

.profile-gender-birth ul li {
    list-style: none;
    margin-top: 15px;
}

.profile-gender-birth ul li span {
    font-family: "Muli", sans-serif;
    /* font-weight: 600; */
    font-size: 16px;
    color: #a7b9c5;
    display: block;
}

.profile-gender-birth ul li label {
    font-family: "Muli", sans-serif;
    /* font-weight: 600; */
    font-size: 16px;
    color: #1d3152;
    margin-top: 2px;
    display: block;
    word-break: break-all;
}

.segment-content {
    border-bottom: 1px solid #8c96a7;
    padding-bottom: 15px;
}

.segment-content h4 {
    color: #1d3152;
    font-family: "Muli", sans-serif;
    font-weight: 600;
    font-size: 16px;
    margin: 0;
}

.segment-content img {
    float: right;
    margin-top: 5px;
}

.profile-segment-section {
    padding: 30px;
}

.segment-listing ul {
    padding: 0;
    margin: 0;
}

.circleSpinner {
    width: 100%;
    text-align: center;
    margin-top: 50px;
}

.segment-listing ul li {
    list-style: none;
    display: inline-block;
    margin-right: 5px;
    margin-bottom: 5px;
    margin-top: 5px;
    width: auto;
    max-width: 100px;
}

.segment-listing ul li img {
    float: right;
    margin-left: 5px;
}

.segment-listing ul li span {
    background: #f2f4f5;
    border: 1px solid #d2dade;
    padding: 6px 10px;
    color: #1d3152;
    font-family: "Muli", sans-serif;
    font-weight: 400;
    font-size: 12px;
    border-radius: 5px;
    display: block;
}

.segment-notes-content {
    border-bottom: 1px solid #8c96a7;
    padding-bottom: 15px;
    margin-top: 30px;
}

.segment-notes-content h4 {
    color: #1d3152;
    font-family: "Muli", sans-serif;
    font-weight: 600;
    font-size: 16px;
    margin: 0;
}

.segment-notes-textarea textarea {
    width: 100%;
    border-radius: 5px;
    border: 1px solid #d2dade;
    margin-top: 15px;
    padding: 15px;
}

.contacts-sidebar-content {
    position: relative;
}

.contacts-sidebar-content .sidebar-closebtn {
    position: absolute;
    top: 10px;
    right: 10px;
}

.sidebar-footer p {
    color: #3aaf50;
    font-family: "Muli", sans-serif;
    font-size: 12px;
    font-weight: 700;
    text-align: center;
}

.segment-content-dropdown {
    border: 1px solid #a7b9c5;
    border-radius: 5px;
    padding: 15px;
    position: absolute;
    right: 20px;
    background: #fff;
    width: 75%;
}

.segment-content-dropdown ul {
    padding: 0;
    margin: 0;
}

.segment-content-dropdown ul li {
    list-style: none;
    padding: 10px 0;
}

.segment-content-dropdown ul li span {
    color: #a7b9c5;
    font-family: "Muli", sans-serif;
    font-size: 12px;
    font-weight: 400;
}

/*.segment-content-dropdown{
  position: relative;
}*/

.segment-content-dropdown img {
    float: right;
    margin-top: 5px;
    position: absolute;
    top: 10px;
    right: 10px;
}

.segment-content-dropdown h4 {
    color: #a7b9c5;
    font-family: "Muli", sans-serif;
    font-size: 12px;
    font-weight: 600;
    margin-bottom: 20px;
}

.segment-checkbox input {
    display: none;
}

.segment-checkbox .active-items:checked + label {
    background-color: #1d3152;
    border: 2px solid #1d3152;
    color: #99a1a7;
}

.segment-checkbox .active-items + label {
    border: 2px solid #707070;
    padding: 6px;
    border-radius: 3px;
    margin-right: 20px;
    display: inline-block;
    position: relative;
}

.segment-checkbox .active-items:checked + label:after {
    content: "\2714";
    font-size: 12px;
    position: absolute;
    top: -2px;
    left: 0px;
    color: #fff;
}

.segment-content-dropdown ul li .segment-checkbox {
    float: left;
}

/* Contact page Sidebar section ends here */

/* Reports Page starts here */

.reports-content {
    overflow-y: scroll;
    padding: 40px;
    background: #fff;
    width: 100%;
    height: 100vh;
}

/* .reports-content-new-width{
  overflow-y: scroll;
} */

.main-content .campainDetailIsOpen {
    overflow-y: scroll;
    right: 0 !important;
    transition: right 1.5s;
}

.reports-content-new-width {
    width: 70% !important;
    transition: width 1.5s;
}

.reports-content.false {
    width: 100% !important;
    transition: width 1.5s;
}

.main-content .campainDetailIsClosed {
    right: -30%;
    transition: right 1.5s;
}

.reports-content-header {
    margin-bottom: 20px;
}

.reports-content-header form {
    position: relative;
}

.reports-content-header form img {
    position: absolute;
    right: 15px;
    top: 35%;
}

.reports-content-header h3 {
    font-family: "Montserrat", sans-serif;
    color: #1d3152;
    font-size: 24px;
    font-weight: 700;
    margin: 0;
    text-align: left;
}

.reports-content-header p {
    font-family: "Montserrat", sans-serif;
    color: #1d3152;
    font-size: 16px;
    font-weight: 400;
    text-align: left;
}

.reports-content-header input {
    background: #fff;
    width: 100%;
    border: 1px solid #bec4c7;
    border-radius: 5px;
    padding: 15px 18px;
}

.reports-content-header input::placeholder {
    color: #bec4c7;
    font-size: 12px;
    font-family: "Montserrat", sans-serif;
    font-weight: 400;
}

.reports-table-content {
    margin-bottom: 60px;
    word-break: break-all;
    position: relative;
}

.reports-table-content table {
    width: 100%;
    border-collapse: collapse;
}

.reports-table-content table tr:hover {
    background: #eeeff0;
    transition: all 0.3s;
}

.reports-table-content table tr td {
    border-bottom: 1px solid #bec4c7;
    color: #1d3152;
    font-size: 12px;
    font-family: "Muli", sans-serif;
    font-weight: 400;
    padding: 15px;
    position: relative;
}

.reports-table-content table tr td .dateonhover {
    position: absolute;
    top: 80%;
}

.reports-table-content table tr td .dateonhover p {
    color: #344c60;
    font-size: 8px;
    font-family: "Muli", sans-serif;
    font-weight: 400;
    border-radius: 5px;
    border: 1px solid #d2dade;
    margin: 0;
    display: inline-block;
    padding: 8px 10px;
    background: #fff;
}

.reports-table-content table tr td .recipientsonhover {
    position: absolute;
    right: 0;
    background: #fff;
    display: inline-block;
    border-radius: 5px;
    border: 1px solid #d2dade;
    padding: 8px 10px;
    z-index: 1;
}

.reports-table-content table tr td .recipientsonhover label {
    color: #a7b9c5;
    font-size: 8px;
    font-family: "Muli", sans-serif;
    font-weight: 600;
    display: inline-block;
    margin-bottom: 5px;
}

.reports-table-content table tr td .recipientsonhover p {
    color: #1d3152;
    font-size: 8px;
    font-family: "Muli", sans-serif;
    font-weight: 400;
    margin: 0;
}

.reports-table-content table tr td ul {
    margin: 0;
    padding: 0;
}

.reports-table-content table tr td ul li {
    list-style: none;
    display: inline-block;
    margin-right: 7px;
    height: 30px;
}

.reports-table-content table tr td ul li div {
    color: #1d3152;
    font-size: 12px;
    font-family: "Muli", sans-serif;
    font-weight: 400;
    padding: 6px 8px;
    border-radius: 5px;
    border: 1px solid #a7b9c5;
    background: #f9fafd;
}

.reports-table-content table th:nth-child(1),
.reports-table-content table th:nth-child(2),
.reports-table-content table th:nth-child(3) {
    border-bottom: 1px solid #bec4c7;
    padding: 15px;
    font-family: "Muli", sans-serif;
    font-weight: 700;
    font-size: 14px;
    color: #9dabb4;
    width: 23.3333333333%;
    text-align: left;
}

.reports-table-content table th:nth-child(4),
.reports-table-content table th:nth-child(5),
.reports-table-content table th:nth-child(6) {
    border-bottom: 1px solid #bec4c7;
    padding: 15px;
    font-family: "Muli", sans-serif;
    font-weight: 700;
    font-size: 14px;
    color: #9dabb4;
    width: 10%;
    text-align: left;
}

.reports-table-content h5 {
    font-family: "Muli", sans-serif;
    font-weight: 300;
    font-size: 14px;
    color: #4ca35c;
    text-align: left;
    margin: 0;
}

/* Reports page ends here */

/* Grid Classes starts here */

*,
::after,
::before {
    box-sizing: border-box;
}

.container,
.container-fluid {
    width: 100%;
    /* padding-right: 15px; */
    /* padding-left: 15px; */
    margin-right: auto;
    margin-left: auto;
    max-width: 100vw;
}

.row {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    /*  margin-right: -15px;
  margin-left: -15px;*/
}

.w-1 {
    -ms-flex: 0 0 8.333333%;
    flex: 0 0 8.333333%;
    max-width: 8.333333%;
}

.w-1-5 {
    -ms-flex: 0 0 12.5%;
    flex: 0 0 12.5%;
    max-width: 12.5%;
}

.w-2 {
    -ms-flex: 0 0 16.666667%;
    flex: 0 0 16.666667%;
    max-width: 16.666667%;
}

.w-2-5 {
    -ms-flex: 0 0 20.8333325%;
    flex: 0 0 20.8333325%;
    max-width: 20.8333325%;
}

.w-3 {
    -ms-flex: 0 0 25%;
    flex: 0 0 25%;
    max-width: 25%;
}

.w-4 {
    -ms-flex: 0 0 33.333333%;
    flex: 0 0 33.333333%;
    max-width: 33.333333%;
}

.w-5 {
    -ms-flex: 0 0 41.666667%;
    flex: 0 0 41.666667%;
    max-width: 41.666667%;
}

.w-6 {
    -ms-flex: 0 0 50%;
    flex: 0 0 50%;
    max-width: 50%;
}

.w-7 {
    -ms-flex: 0 0 58.333333%;
    flex: 0 0 58.333333%;
    max-width: 58.333333%;
}

.w-8 {
    -ms-flex: 0 0 66.666667%;
    flex: 0 0 66.666667%;
    max-width: 66.666667%;
}

.w-9 {
    -ms-flex: 0 0 75%;
    flex: 0 0 75%;
    max-width: 75%;
}

.w-9-5 {
    -ms-flex: 0 0 70.8333335%;
    flex: 0 0 70.8333335%;
    max-width: 70.8333335%;
}

.w-10 {
    -ms-flex: 0 0 83.333333%;
    flex: 0 0 83.333333%;
    max-width: 83.333333%;
}

.w-10-5 {
    -ms-flex: 0 0 87.5%;
    flex: 0 0 87.5%;
    max-width: 87.5%;
}

.w-11 {
    -ms-flex: 0 0 91.666667%;
    flex: 0 0 91.666667%;
    max-width: 91.666667%;
}

.w-12 {
    -ms-flex: 0 0 100%;
    flex: 0 0 100%;
    max-width: 100%;
}

.text-center {
    text-align: center !important;
}

.text-left {
    text-align: left;
}

.text-right {
    text-align: right;
}

.float-left {
    float: left;
}

.float-right {
    float: right;
}

.p-0 {
    padding: 0 !important;
}

.img-fluid {
    max-width: 100%;
    height: auto;
    cursor: pointer;
    display: inline;
}

img {
    vertical-align: middle;
    border-style: none;
}

/* Grid Classes ends here */

/* Chats Settings Modal CSS starts here */

.chats-autoresponder-modal {
    display: flex;
    justify-content: center;
    align-items: flex-start;
    position: fixed;
    z-index: 1;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    overflow: auto;
    background-color: rgb(0, 0, 0);
    background-color: rgba(0, 0, 0, 0.4);
    overflow: hidden;
}

/* Modal Content/Box */
.chats-autoresponder-modal .modal-content {
    background-color: #fefefe;
    margin: 5% auto 0;
    border: 1px solid #888;
    border-radius: 5px;
    width: 574px;
    height: auto;
}

/* The Close Button */
.chats-autoresponder-modal .close {
    color: #aaa;
    float: right;
    font-size: 28px;
    font-weight: bold;
}

.chats-autoresponder-modal .close:hover,
.chats-autoresponder-modal .close:focus {
    color: black;
    text-decoration: none;
    cursor: pointer;
}

.chats-autoresponder-modal .setting-modals h3 {
    text-align: left;
    color: #344c60;
    font-family: "Montserrat", sans-serif;
    font-size: 22px;
    font-weight: 700;
    margin: 0;
}

.chats-autoresponder-modal .setting-tab {
    margin-top: 10px;
    border-bottom: 1px solid #c2c5c7;
}

.chats-autoresponder-modal .setting-tab .tab-heading h4 {
    color: #a7b9c5;
    font-family: "Montserrat", sans-serif;
    font-size: 14px;
    font-weight: 400;
    text-align: center;
    margin-top: 0;
    cursor: pointer;
}

.chats-autoresponder-modal .setting-tab .active .tab-heading h4 {
    color: #3aaf50;
    font-weight: 700;
}

.chats-autoresponder-modal .setting-tab .active {
    border-bottom: 4px solid #3aaf50;
}

.chats-autoresponder-modal .setting-tab-content {
    padding: 20px;

    max-height: 80vh;
}

.chats-autoresponder-modal .setting-tab-content h5 {
    color: #344c60;
    font-family: "Muli", sans-serif;
    font-size: 14px;
    font-weight: 600;
    margin: 0;
}

.inactivehourstoggle {
    float: right;
    margin-top: 0;
}

.inactivehourstoggle .toggleswitching .css-xjimrv-Switch {
    width: 28px;
    height: 17px;
}

.inactivehourstoggle .toggleswitching .css-14hjwnh-Switch,
.inactivehourstoggle .toggleswitching .css-153wf2s-Switch {
    border-radius: 10px;
}

.inactivehourstoggle .toggleswitching .css-1v96km2-Switch {
    left: 13px;
}

.inactivehourstoggle .toggleswitching .css-19a995p-Switch {
    right: 13px;
}

.chats-autoresponder-modal .setting-tab-content h4 {
    color: #8a9198;
    font-family: "Muli", sans-serif;
    font-size: 14px;
    font-weight: 400;
}

.chats-autoresponder-modal .setting-tab-content h4 img {
    margin-right: 10px;
    width: 16px;
    margin-top: -5px;
}

.setting-modal-form {
    padding: 15px 0 0;
}

.setting-modal-form textarea {
    color: #344c60;
    font-family: "Muli", sans-serif;
    font-size: 12px;
    font-weight: 400;
    padding: 20px 15px;
    border: 1px solid #d8dbdd;
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
    width: 100%;
}

.setting-modal-form textarea::placeholder {
    color: #c6d0d5;
    font-family: "Muli", sans-serif;
    font-size: 14px;
    font-weight: 400;
}

.setting-modal-form label {
    color: #a7b9c5;
    font-family: "Montserrat", sans-serif;
    font-size: 12px;
    font-weight: 600;
    display: block;
    margin-bottom: 5px;
}

.setting-modal-form input {
    width: 100%;
    border: 1px solid #d2dade;
    border-radius: 5px;
    padding: 10px;
    margin-bottom: 20px;
}

/* .setting-modal-form input:focus {
  outline: none;
  box-shadow: inset 0 0 1px rgba(58, 175, 80, 1), 0 0 0px 1px rgba(58, 175, 80, 1);
} */

.setting-modal-form input:focus label {
    color: #3aaf50;
}

.setting-modal-form input::placeholder {
    color: #c6d0d5;
    font-family: "Muli", sans-serif;
    font-size: 12px;
    font-weight: 400;
}

.setting-modal-form .total-characters {
    text-align: right;
}

.setting-modal-form .total-characters span {
    color: #a7b9c5;
    font-family: "Muli", sans-serif;
    font-size: 10px;
    font-weight: 400;
}

.addinghours {
    margin-top: 25px;
}

.addinghoursbox {
    text-align: right;
}

.addinghoursbox select,
.addinghoursbox input {
    color: #344c60;
    font-size: 12px;
    font-weight: 400;
    width: 100%;
    border: 1px solid #d2dade;
    border-radius: 5px;
    padding: 15px;
    letter-spacing: 1px;
}

.addinghoursbox input::placeholder {
    color: #c6d0d5;
    font-family: "Muli", sans-serif;
    font-size: 12px;
    font-weight: 400;
    letter-spacing: 1px;
}

.addinghoursbox label {
    color: #344c60;
    font-family: "Muli", sans-serif;
    font-size: 14px;
    font-weight: 400;
    display: inline-block;
    margin-top: 12px;
    margin-left: 5px;
    margin-right: 5px;
    text-align: center;
    letter-spacing: 1px;
}

.addinghoursbox .close-btn {
    color: #d2dade;
    font-size: 36px;
    float: right;
    cursor: pointer;
}

.addinghours .addinghoursbox select option {
    color: #a7b9c5;
    font-family: "Muli", sans-serif;
    font-size: 14px;
    font-weight: 600;
    padding: 20px;
    margin-bottom: 10px;
    position: relative;
    border: 1px solid #d2dade;
}

.setting-tab-content-emailalerts {
    padding: 20px;
}

/****************active checkbox starts***************/

.setting-tab-content-emailalerts input[type="checkbox"] {
    display: none;
}

.setting-tab-content-emailalerts .active-checkbox + label {
    border: 2px solid #707070;
    padding: 6px;
    border-radius: 3px;
    margin-right: 10px;
    margin-bottom: -2px;
    display: inline-block;
    position: relative;
}

.setting-tab-content-emailalerts .active-checkbox:checked + label {
    border: 2px solid #707070;
    color: #99a1a7;
}

.setting-tab-content-emailalerts .active-checkbox:checked + label:after {
    content: "";
    position: absolute;
    top: 1px;
    left: 1px;
    color: #99a1a7;
    width: 80%;
    height: 80%;
    border-radius: 2px;
    background: #3aaf50;
}

/***************active checkbox ends******************/

.setting-tab-content-emailalerts h5 {
    color: #a7b9c5;
    font-family: "Muli", sans-serif;
    font-size: 14px;
    font-weight: 600;
}

.setting-tab-content-emailalerts p span {
    color: #344c60;
    font-family: "Muli", sans-serif;
    font-size: 14px;
    font-weight: 600;
}

.setting-tab-content-emailalerts select {
    border-left: 0;
    border-right: 0;
    border-top: 0;
    border-bottom: 1px solid #344c60;
    border-bottom-style: dotted;
}

.setting-tab-content-emailalerts select option {
    border: 1px solid #d2dade;
}

.setting-tab-content-emailalerts textarea {
    padding: 20px;
    width: 100%;
    border: 1px solid #d8dbdd;
    border-radius: 5px;
}

.setting-tab-content-emailalerts textarea::placeholder {
    color: #a7b9c5;
    font-family: "Muli", sans-serif;
    font-size: 14px;
    font-weight: 400;
}

.alert-message-footer p {
    color: #344c60;
    font-family: "Muli", sans-serif;
    font-size: 14px;
    font-weight: 600;
}

.chat-form-btn {
    margin-top: 5px;
    text-align: right;
}

.chat-form-btn .valid {
    background: #3aaf50 !important;
}

.setting-tab-content-emailalerts button {
    margin-top: 10px;
    text-align: right;
}

.setting-tab-content-account {
    padding: 20px 50px;
    margin-top: 20px;
}

.account-logo {
    border-radius: 50%;
    border: 1px solid #a7b9c5;
    background: #f2f4f5;
    text-align: center;
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center center;
    height: 80px;
    width: 80px;
}

.account-row1-box {
    padding: 0 15px;
}

.account-row1-box h4 {
    color: #a7b9c5;
    font-family: "Muli", sans-serif;
    font-size: 14px;
    font-weight: 600;
    margin-top: 0;
    margin-bottom: 5px;
}

.account-row1-box p {
    color: #344c60;
    font-family: "Muli", sans-serif;
    font-size: 14px;
    font-weight: 600;
    margin-top: 0;
    margin-bottom: 5px;
    word-break: break-all;
}

.account-row1-box input {
    border: 1px solid #d2dade;
    width: 100%;
    border-radius: 5px;
    padding: 5px 10px;
}

.readonlyinput:focus {
    outline: none !important;
    box-shadow: none !important;
    border: none !important;
}

.account-row1-box input::placeholder {
    color: #a7b9c5;
    font-family: "Muli", sans-serif;
    font-size: 12px;
    font-weight: 600;
}

.account-row1-box input:focus {
    box-shadow: inset 0 0 1px #a7b9c5, 0 0 0px 1px #a7b9c5 !important;
}

.account-row1-box a {
    color: #a7b9c5;
    font-family: "Muli", sans-serif;
    font-size: 14px;
    font-weight: 600;
    text-decoration: none;
}

.account-row2-box h4 {
    color: #a7b9c5;
    font-family: "Muli", sans-serif;
    font-size: 14px;
    font-weight: 600;
}

.account-row2-box ul {
    padding: 5px;
    margin: 0;
    border: 1px solid #d2dade;
    border-radius: 5px;
    width: 100%;
}

.account-row2-box ul li {
    list-style: none;
    display: inline-block;
    border: 1px solid #a7b9c5;
    border-radius: 5px;
    margin-right: 10px;
    padding: 5px 10px;
    background: #f2f4f5;
}

.account-row2-box ul li span {
    color: #a7b9c5;
    font-family: "Muli", sans-serif;
    font-size: 12px;
    font-weight: 400;
}

.account-row3-box h4 {
    color: #a7b9c5;
    font-family: "Muli", sans-serif;
    font-size: 14px;
    font-weight: 600;
}

.account-row3-box textarea {
    padding: 20px;
    width: 100%;
    border: 1px solid #d8dbdd;
    border-radius: 5px;
    color: #a7b9c5;
    font-family: "Muli", sans-serif;
    font-size: 14px;
    font-weight: 400;
}

.account-row3-box p {
    color: #344c60;
    font-family: "Muli", sans-serif;
    font-size: 14px;
    font-weight: 600;
    text-align: center;
}

.account-row3-box p span {
    text-decoration: underline;
    color: #3aaf50;
}

.chat-right-menu li .newbutton-dropdown {
    position: absolute;
    right: 0;
    border-radius: 5px;
}

.chat-right-menu li .newbutton-dropdown ul {
    width: 200px;
    margin: 0;
    padding: 10px 0;
    background: #fff;
    border-radius: 5px;
    box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.16), 0 2px 10px 0 rgba(0, 0, 0, 0.12);
}

.newbutton-dropdown ul li {
    padding: 0 10px;
    list-style: none;
    margin: 0 !important;
    width: 100%;
}

.newbutton-dropdown ul li h3 {
    color: #1d3152;
    font-family: "Montserrat", sans-serif;
    font-size: 12px;
    text-transform: capitalize;
    font-weight: 600;
    display: inline-block;
}

.newbutton-dropdown ul li a {
    color: #1d3152;
    font-family: "Montserrat", sans-serif;
    font-size: 12px;
    text-transform: capitalize;
    font-weight: 600;
    display: inline-block;
    text-decoration: none;
}

.newbutton-dropdown ul {
    padding: 20px 0;
}

.newbutton-dropdown ul li:hover {
    background: #f3f4f5;
    cursor: pointer;
    transition: all 0.3s;
}

.emojisection ul li p {
    color: #a7b9c5;
    font-family: "Montserrat", sans-serif;
    font-size: 12px;
    font-weight: 600;
    margin: 0;
    margin-right: 15px;
    display: inline-block;
}

.requesttoggle {
    float: right;
}

.mail-send-type ul {
    margin: 0;
    padding: 0;
    width: 100%;
    /*  border: 1px solid #D2DADE;
  border-radius: 5px;*/
}

.mail-send-type ul li:first-child {
    border-top: 1px solid #d2dade;
    border-left: 1px solid #d2dade;
    border-bottom: 1px solid #d2dade;
    border-top-left-radius: 5px;
    border-bottom-left-radius: 5px;
}

.mail-send-type ul li:last-child {
    border-top: 1px solid #d2dade;
    border-right: 1px solid #d2dade;
    border-bottom: 1px solid #d2dade;
    border-top-right-radius: 5px;
    border-bottom-right-radius: 5px;
}

.mail-send-type ul li {
    width: 33.3333333333%;
    list-style: none;
    display: inline-block;
    margin: 0;
    border-top: 1px solid #d2dade;
    border-bottom: 1px solid #d2dade;
    cursor: pointer;
    border-left: 1px solid #d2dade;
}

.mail-send-type ul li.active-sentitnow span {
    background: #3aaf50;
    color: #fff;
}

.mail-send-type ul li:not(:last-child) {
    border-right: 1px solid #d2dade;
}

.mail-send-type ul li span {
    color: #a7b9c5;
    font-family: "Montserrat", sans-serif;
    font-size: 12px;
    font-weight: 600;
    text-align: center;
    display: block;
    padding: 10px 0;
}

.pressing-btn-msg {
    display: block;
    height: 100%;
    margin: 20px 0;
}

.pressing-btn-msg p {
    color: #a7b9c5;
    font-family: "Montserrat", sans-serif;
    font-size: 12px;
    font-weight: 600;
    margin: 0;
}

/* Chats Autoresponder Modal CSS ends here */

/* New Campaign Modal CSS starts here */

.yesnobtn {
    background: #d2dade;
    border-radius: 4px;
    display: inline-block;
    line-height: 8px;
    padding: 8px 3px 9px;
    margin: 0;
}

.yesnobtn input {
    display: none;
}

.yesnobtn .checkmark {
    text-transform: uppercase;
    color: #a7b9c5;
    margin: 0;
    font-family: "Montserrat", sans-serif;
    font-size: 12px;
    font-weight: 400;
    padding: 3px 8px;
    border-radius: 4px;
}

.yesnobtn input:checked ~ .checkmark {
    background: #3aaf50;
    color: #fff;
}

.chats-newcampaign-modal {
    display: flex;
    /*    justify-content: center;
  align-items: center;*/
    /* position: fixed;  */
    z-index: 1;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    overflow: auto;
    background: #fff;
    /*background-color: rgb(0,0,0);
  background-color: rgba(0,0,0,0.4);*/
    /*overflow: hidden;*/
}

/* Modal Content/Box */
.chats-newcampaign-modal .modal-content {
    background-color: #fefefe;
    margin: 0 auto;
    /*border: 1px solid #888;*/
    border-radius: 5px;
    width: 100%;
    height: auto;
}

.chats-newcampaign-modal .modal-header {
    padding: 20px 20px 0;
}

/* The Close Button */
.chats-newcampaign-modal .close {
    color: #aaa;
    float: right;
    font-size: 28px;
    font-weight: bold;
}

.chats-newcampaign-modal .close:hover,
.chats-newcampaign-modal .close:focus {
    color: black;
    text-decoration: none;
    cursor: pointer;
}

.chats-newcampaign-modal .newcampaign-modals h3 {
    color: #344c60;
    font-family: "Montserrat", sans-serif;
    font-size: 22px;
    font-weight: 700;
    margin-top: 0;
}

.chats-newcampaign-modal .setting-tab {
    margin-top: 10px;
    border-bottom: 1px solid #c2c5c7;
}

.chats-newcampaign-modal .setting-tab .tab-heading h4 {
    color: #a7b9c5;
    font-family: "Montserrat", sans-serif;
    font-size: 14px;
    font-weight: 400;
    text-align: center;
    margin-top: 0;
}

.chats-newcampaign-modal .setting-tab .active .tab-heading h4 {
    color: #3aaf50;
    font-weight: 700;
}

.chats-newcampaign-modal .setting-tab .active {
    border-bottom: 4px solid #3aaf50;
}

/* .setting-tab .setting-tab-content{
  overflow: auto;
  max-height: 80vh;
} */

.chats-newcampaign-modal .setting-tab-content h5 {
    color: #344c60;
    font-family: "Muli", sans-serif;
    font-size: 14px;
    font-weight: 400;
    margin: 0;
}

.chats-newcampaign-modal .setting-tab-content h4 {
    color: #8a9198;
    font-family: "Muli", sans-serif;
    font-size: 14px;
    font-weight: 400;
}

.chats-newcampaign-modal .setting-tab-content h4 img {
    margin-right: 10px;
}

.newcampaign-modal-form {
    padding: 30px 50px 60px 50px;
}

.newcampaign-modal-form textarea {
    color: #344c60;
    font-family: "Muli", sans-serif;
    font-size: 12px;
    font-weight: 400;
    padding: 20px 15px;
    border: 1px solid #d8dbdd;
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
    width: 100%;
}

.newcampaign-modal-form textarea::placeholder {
    color: #c6d0d5;
    font-family: "Muli", sans-serif;
    font-size: 14px;
    font-weight: 400;
}

.newcampaign-modal-form label {
    color: #748997;
    font-family: "Montserrat", sans-serif;
    font-size: 12px;
    font-weight: 600;
    line-height: 13px;
    display: block;
    margin-bottom: 5px;
}

.newcampaign-modal-form input {
    width: 100%;
    /* border: 1px solid #D2DADE;
border-radius: 5px; */
    border: none;
    padding: 10px;
    /* margin-bottom: 20px; */
}

/* .newcampaign-modal-form input:focus {
  outline: none;
  box-shadow: inset 0 0 1px rgba(58, 175, 80, 1), 0 0 0px 1px rgba(58, 175, 80, 1);
} */

.newcampaign-modal-form input:focus label {
    color: #3aaf50;
}

.newcampaign-modal-form input::placeholder {
    color: #c6d0d5;
    font-family: "Muli", sans-serif;
    font-size: 12px;
    font-weight: 400;
}

.newcampaign-modal-form .total-characters {
    text-align: right;
}

.newcampaign-modal-form .total-characters span {
    color: #a7b9c5;
    font-family: "Muli", sans-serif;
    font-size: 10px;
    font-weight: 400;
}

.gifbox {
    position: absolute;
    bottom: 100%;
    border-radius: 5px;
    background: #f2f4f5;
    border: 1px solid #d2dade;
    width: 610px;
}

.gifbox .gifsearchbox {
    padding: 7px;
    border-bottom: 0.5px solid #e0e0e0;
}

.gifbox .gifsearchbox input {
    background: #fff;
    width: 100%;
    border-radius: 5px;
    border: 1px solid #d2dade;
    padding: 10px 15px;
    margin-bottom: 0 !important;
    /* background: url(../images/newcampaign-searchicon.png); */
    background-repeat: no-repeat;
    background-size: 20px 20px;
    background-position: 98% 50%;
}

.gifbox .gifsearchbox input::placeholder {
    color: #c6d0d5;
    font-family: "Muli", sans-serif;
    font-size: 12px;
    font-weight: 400;
}

.gifbox .gifemojis {
    margin: 10px;
    padding-right: 10px;
    height: 260px;
    overflow-y: scroll;
}

/*Scrollbar Designing starts here*/
/* width */
.gifbox .gifemojis::-webkit-scrollbar {
    width: 5px;
    border-radius: 20px;
}

/* Track */
.gifbox .gifemojis::-webkit-scrollbar-track {
    background: #f1f1f1;
    box-shadow: inset 0 0 5px grey;
    border-radius: 20px;
}

/* Handle */
.gifbox .gifemojis::-webkit-scrollbar-thumb {
    background: #3aaf50;
    border-radius: 20px;
}

/* Handle on hover */
.gifbox .gifemojis::-webkit-scrollbar-thumb:hover {
    background: #555;
    transition: all 0.3s linear;
}

/*Scrollbar Desgining ends here*/

.gifbox .gifemojis .gifemojisbox {
    border-radius: 0;
    background: url(./assets/Newimages/loadingif.gif) no-repeat 50%;
    margin-bottom: 10px;
    margin-right: 10px;
    min-height: 200px;
}

.gifbox .gifemojis .w-4:nth-of-type(3n + 0) .gifemojisbox {
    margin-right: 0;
}

.newcampaign-btn {
    text-align: right;
    margin-top: 30px;
}

.inserturl-button {
    text-align: right;
}

.newcampaign-inserturl {
    width: 80%;
    background: #f2f4f5;
    border: 1px solid #d2dade;
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
    position: absolute;
    bottom: 100%;
    z-index: 1;
}

.inserturl-input {
    padding: 5px 10px;
    background: #f2f4f5;
}

.inserturl-input input {
    color: #344c60;
    font-family: "Muli", sans-serif;
    font-size: 12px;
    font-weight: 400;
    width: 100%;
    border: 1px solid #d2dade;
    border-radius: 5px;
    background: #fff;
    padding: 12px 15px;
    margin-bottom: 0 !important;
}

.inserturl-button {
    padding: 8px 0;
}

.newcampaign-form-btn {
    width: 90%;
    border-radius: 22px;
    background: #3aaf50;
    border: 0;
    color: #fff;
    font-family: "Muli", sans-serif;
    font-size: 12px;
    font-weight: 700;
    margin: 0 5px;
    padding: 10px 13px;
}

.newcampaign-form-btn:hover {
    background: #c6d0d5;
    transition: all 0.3s;
}

.newcampaign-form-sendbtn {
    border-radius: 22px;
    background: #3aaf50;
    border: 0;
    color: #fff;
    font-family: "Muli", sans-serif;
    font-size: 12px;
    font-weight: 700;
    margin: 50px 5px 0 5px;
    padding: 10px 30px;
}

.newcampaign-form-sendbtn:hover {
    background: #c6d0d5;
    transition: all 0.3s;
}

.bracket3dots {
    padding: 5px 15px;
    background: #f2f4f5;
    border-radius: 0;
    border-top: 1px solid #d2dade;
    position: absolute;
    bottom: 100%;
    left: 0;
    width: 100%;
}

.bracket3dots .addedimage {
    position: relative;
}

.bracket3dots .addedimage::before {
    content: "";
    position: absolute;
    top: 5px;
    left: 5px;
    /* background: url(../images/3dotsclose-btn.png); */
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center;
    width: 15px;
    height: 15px;
}

.auto-reply-msg label {
    color: #3aaf50;
}

.scheduledbox label {
    font-family: "Montserrat", sans-serif;
    font-size: 14px;
    font-weight: 600;
    color: #d2dade;
    text-align: center;
    margin-top: 10px;
}

.scheduledbox select {
    font-family: "Montserrat", sans-serif;
    font-size: 14px;
    font-weight: 400;
    color: #a7b9c5;
    width: 100%;
    border-radius: 20px;
    border: 1px solid #d2dade;
    padding: 10px;
}

.scheduledbox input[type="date"] {
    border: 1px solid #d2dade;
    border-radius: 5px;
    width: 50%;
    padding: 10px;
    margin-bottom: 0 !important;
    margin-top: 5px;
    color: #344c60;
    font-family: "Muli", sans-serif;
    font-size: 14px;
    font-weight: 400;
}

.senttest-input input {
    border: 1px solid #d2dade;
    border-radius: 5px;
    width: 40%;
    padding: 10px;
    margin-bottom: 0 !important;
    margin-top: 5px;
    color: #344c60;
    font-family: "Montserrat", sans-serif;
    font-size: 15px;
    font-weight: 600;
}

.senttest-input ul li .sendtest-checkbox + label {
    border: 2px solid #707070;
    padding: 6px;
    border-radius: 3px;
    margin: 0 10px 0 0;
    display: inline-block;
    position: relative;
}

.senttest-input ul li .sendtest-checkbox:checked + label {
    border: 2px solid #707070;
    color: #99a1a7;
}

.senttest-input ul li .sendtest-checkbox:checked + label:after {
    content: "";
    position: absolute;
    top: 1px;
    left: 1px;
    color: #99a1a7;
    width: 80%;
    height: 80%;
    border-radius: 2px;
    background: #3aaf50;
}

.senttest-input input[type="checkbox"] {
    /*  float: left !important;
width: 20px !important;
margin: 7px 10px 0 0 !important;*/
    display: none;
}

.senttest-input input::placeholder {
    color: #344c60;
    font-family: "Muli", sans-serif;
    font-size: 12px;
    font-weight: 600;
    text-align: left;
}

.senttest-input ul {
    margin: 0;
    padding: 0;
}

.senttest-input ul li {
    width: 46%;
    display: inline-block;
    list-style: none;
    margin-top: 10px;
    line-height: 0;
}

.senttest-input ul li span {
    color: #344c60;
    font-family: "Muli", sans-serif;
    font-size: 12px;
    font-weight: 600;
}

.sendtest-readmore {
    display: inline-block;
}

.sendtest-readmore span {
    color: #c6d0d5;
    font-family: "Muli", sans-serif;
    font-size: 12px;
    font-weight: 400;
    cursor: pointer;
}

.sendtest-readmore img {
    margin: 0 5px 0 10px;
}

.sendtest-btn {
    border-radius: 22px;
    background: transparent;
    border: 1px solid #1d3152;
    color: #1d3152;
    font-family: "Muli", sans-serif;
    font-size: 12px;
    font-weight: 700;
    margin: 0 5px;
    padding: 10px 20px;
}

.sendtest-btn:hover {
    background: #1d3152;
    color: #fff;
    transition: all 0.3s;
}

/* New Campaign Modal CSS ends here */

/* Auto Responder Success Modal starts here */

.auto-responder-success-modal {
    display: block;
    position: fixed;
    z-index: 1;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    overflow: auto;
    background-color: rgb(0, 0, 0);
    background-color: rgba(0, 0, 0, 0.4);
}

/* Modal Content/Box */
.auto-responder-success-modal .auto-modal-content {
    background-color: #fff;
    margin: 5% auto 0;
    padding: 20px;
    border: 1px solid #888;
    width: 311px;
    height: auto;
    border-radius: 20px;
    border-top: 20px solid #3aaf50;
}

.auto-responder-success-modal .auto-modal-content p {
    font-family: "Montserrat", sans-serif;
    font-size: 12px;
    font-weight: 400;
    color: #344c60;
    text-align: center;
}

.auto-responder-success-modal .auto-modal-content p img {
    margin-left: 10px;
}

.auto-responder-success-modal .auto-modal-content a {
    font-family: "Montserrat", sans-serif;
    font-size: 10px;
    font-weight: 500;
    color: #638ccf;
    text-align: center;
    display: block;
}

/* Auto responder success modal ends here */

/* Toggle CSS starts here*/

.toggleswitching .toggleswitch {
    position: relative;
    display: inline-block;
    width: 28px;
    height: 16px;
    background-color: rgba(0, 0, 0, 0.25);
    border-radius: 20px;
    transition: all 0.3s;
    padding: 0;
    margin-top: 5px;
}

.toggleswitching .toggleswitch::after {
    content: "";
    position: absolute;
    width: 14px;
    height: 14px;
    border-radius: 50%;
    background-color: white;
    top: 1px;
    left: 1px;
    transition: all 0.3s;
}

.toggleswitching .togglecheckbox:checked + .toggleswitch::after {
    left: 13px;
}

.toggleswitching .togglecheckbox:checked + .toggleswitch {
    background-color: #3aaf50;
}

.toggleswitching .togglecheckbox {
    display: none;
}

.auto-reply-btn {
    float: right;
    margin-left: 10px;
}

.auto-reply-btn.text-right .toggleswitching {
    padding-right: 20px;
}

/* Toggle CSS ends here */

/* Scheduled Message Modal starts here */

.scheduled-message-modal {
    display: block;
    position: fixed;
    z-index: 1;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    overflow: auto;
    background-color: rgb(0, 0, 0);
    background-color: rgba(0, 0, 0, 0.4);
}

/* Modal Content/Box */
.scheduled-message-modal .scheduled-modal-content {
    background-color: #fff;
    margin: 5% auto 0;
    border: 1px solid #888;
    width: 465px;
    height: auto;
}

.scheduled-message-modal .scheduled-modal-header {
    background: #1d3152;
    padding: 20px;
}

.scheduled-message-modal .scheduled-modal-header h3 {
    font-family: "Montserrat", sans-serif;
    font-size: 20px;
    font-weight: 600;
    color: #fff;
    margin: 0;
}

.scheduled-text {
    padding: 20px;
}

/* The Close Button */
.scheduled-message-modal .close {
    color: #fff !important;
    float: right;
    font-size: 24px;
    font-weight: bold;
}

.scheduled-message-modal .close:hover,
.scheduled-message-modal .close:focus {
    color: black;
    text-decoration: none;
    cursor: pointer;
}

.scheduled-message-modal .scheduled-modal-content p {
    font-family: "Montserrat", sans-serif;
    font-size: 16px;
    font-weight: 500;
    color: #344c60;
    margin: 0;
}

.schedule-cancel-btn {
    border-radius: 22px;
    background: #a7b9c5;
    border: 0;
    color: #fff;
    font-family: "Muli", sans-serif;
    font-size: 12px;
    font-weight: 700;
    margin: 0 5px;
    padding: 10px 20px;
    text-decoration: none;
    display: inline-block;
}

.schedule-cancel-btn:hover {
    background: #1d3152;
    color: #fff;
    transition: all 0.3s;
}

.schedule-modal-btn {
    border-radius: 22px;
    background: #1d3152;
    border: 0;
    color: #fff;
    font-family: "Muli", sans-serif;
    font-size: 12px;
    font-weight: 700;
    margin: 0 5px;
    padding: 10px 20px;
    text-decoration: none;
    display: inline-block;
}

.schedule-modal-btn:hover {
    background: #a7b9c5;
    color: #fff;
    transition: all 0.3s;
}

.scheduled-btn {
    text-align: right;
    margin-top: 30px;
}

/* Scheduled Message Modal ends here */

/* Delete contact Modal starts here*/

.delete-contact-modal {
    display: block;
    position: fixed;
    z-index: 3;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    overflow: hidden;
    background-color: rgba(0, 0, 0, 0.11);
    /* background-color: rgb(0,0,0); */
}

/* Modal Content/Box */
.delete-contact-modal .delete-contact-modal-content {
    background-color: #fff;
    margin: 15% auto 0;
    /* border: 1px solid #888; */
    box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.16), 0 2px 10px 0 rgba(0, 0, 0, 0.12);
    width: 517px;
    height: auto;
}

.delete-contact-modal .delete-contact-modal-header {
    padding: 15px 25px;
}

.delete-contact-modal .delete-contact-modal-header.red {
    background: #eb5e5e;
}

.delete-contact-modal .delete-contact-modal-header.green {
    background: #4eb762;
}

.delete-contact-modal .delete-contact-modal-header.blue {
    background: #1d3152;
}

.delete-contact-modal .delete-contact-modal-header h3 {
    font-family: "Montserrat", sans-serif;
    font-size: 20px;
    font-weight: 600;
    color: #fff;
    margin: 0;
}

.delete-contact-modal-text {
    padding: 30px;
}

/* The Close Button */
.delete-contact-modal .close {
    color: #fff !important;
    float: right;
    font-size: 36px;
    font-weight: 300;
    line-height: 15px;
}

.delete-contact-modal .close:hover,
.delete-contact-modal .close:focus {
    color: black;
    text-decoration: none;
    cursor: pointer;
}

.delete-contact-modal .delete-contact-modal-content p {
    font-family: "Montserrat", sans-serif;
    font-size: 16px;
    font-weight: 500;
    color: #344c60;
    margin: 0;
}

.delete-contact-modal-button .delete-contact-modal-btn {
    text-align: right;
}

.delete-contact-modal-button {
    padding: 20px;
    text-align: right;
}

.delete-contact-modal-btn {
    border-radius: 22px;
    border: 0;
    color: #fff;
    font-family: "Muli", sans-serif;
    font-size: 12px;
    font-weight: 700;
    margin: 0 5px;
    padding: 10px 20px;
    text-decoration: none;
    display: inline-block;
    cursor: pointer;
}

.delete-contact-modal-btn.red {
    background: #eb5e5e;
}

.delete-contact-modal-btn.green {
    background: #4eb762;
}

.delete-contact-modal-btn.blue {
    background: #1d3152;
}

.delete-contact-modal-btn:hover {
    background: #1d3152;
    color: #fff;
    transition: all 0.3s;
}

/* Delete contact Modal ends here */

/* Import Contact CSV Modal starts here */

.import-contact-csv-modal {
    display: block;
    position: fixed;
    z-index: 1;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    overflow: hidden;
    /* background-color: rgb(0,0,0);
  background-color: rgba(0,0,0,0.4); */
}

/* Modal Content/Box */
.import-contact-csv-modal .importcontactcsv-modal-content {
    background-color: #fff;
    margin: 5% auto;
    border: 1px solid #888;
    width: 766px;
    height: auto;
}

.import-contact-csv-modal .importcontactcsv-modal-content.green {
    border-top: 20px solid #4eb762;
}

.import-contact-csv-modal .importcontactcsv-modal-content.red {
    border-top: 20px solid #eb5f5f;
}

.import-contact-csv-modal .importcontactcsv-modal-header {
    padding: 30px 50px;
}

.import-contact-csv-modal .importcontactcsv-modal-header h3 {
    font-family: "Montserrat", sans-serif;
    font-size: 20px;
    font-weight: 600;
    color: #344c60;
    margin: 0;
}

.importcontactcsv-text {
    padding: 0 50px 30px;
}

.importcontactcsv-text .tabledata-listing {
    border-radius: 10px;
}

/* The Close Button */
.import-contact-csv-modal .close {
    color: #1d3152 !important;
    float: right;
    font-size: 24px;
    font-weight: bold;
}

.import-contact-csv-modal .close:hover,
.import-contact-csv-modal .close:focus {
    color: black;
    text-decoration: none;
    cursor: pointer;
}

.import-contact-csv-modal .importcontactcsv-modal-content p {
    font-family: "Montserrat", sans-serif;
    font-size: 16px;
    font-weight: 400;
    color: #344c60;
    margin-top: 18px;
    margin-bottom: 0;
}

.import-contact-csv-modal .importcontactcsv-modal-content label {
    font-family: "Montserrat", sans-serif;
    font-size: 12px;
    font-weight: 500;
    color: #a7b9c5;
    margin-top: 18px;
    padding-bottom: 5px;
    display: block;
    border-bottom: 1px solid #a7b9c5;
}

.importcontactcsv-modal-btn {
    border-radius: 22px;
    background: #1d3152;
    border: 0;
    color: #fff;
    font-family: "Muli", sans-serif;
    font-size: 12px;
    font-weight: 700;
    margin: 0 5px;
    padding: 10px 20px;
    text-decoration: none;
    display: inline-block;
    cursor: pointer;
}

.importcontactcsv-modal-btn:hover {
    background: #a7b9c5;
    color: #fff;
    transition: all 0.3s;
}

.importcontactcsv-modal-cancel-btn {
    border-radius: 22px;
    background: #a7b9c5;
    border: 0;
    color: #fff;
    font-family: "Muli", sans-serif;
    font-size: 12px;
    font-weight: 700;
    margin: 0 5px;
    padding: 10px 20px;
    text-decoration: none;
    display: inline-block;
}

.importcontactcsv-modal-cancel-btn:hover {
    background: #1d3152;
    color: #fff;
    transition: all 0.3s;
}

.importcontactcsv-btn {
    text-align: right;
    margin-top: 30px;
}

.importcontactcsv-text p:first-child {
    /* margin-bottom: 30px; */
}

.importcontactcsv-text p img {
    margin-right: 20px;
    width: 16px;
}

.importcontactcsv-text table {
    width: 100%;
    /* border-top: 1px solid #A7B9C5; */
    padding-top: 5px;
}

.importcontactcsv-text .tabledata-listing {
    height: 140px;
    overflow-y: scroll;
}

.importcontactcsv-text table td {
    color: #344c60;
    font-family: "Muli", sans-serif;
    font-size: 12px;
    font-weight: 400;
    padding: 5px 0;
}

/* Import Contact CSV Modal ends here */

/* Import Contact Modal starts here */

.import-contact-modal {
    display: block;
    position: fixed;
    z-index: 1;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    overflow: auto;
    /* background-color: rgb(0,0,0);
  background-color: rgba(0,0,0,0.4); */
}

/* Modal Content/Box */
.import-contact-modal .importcontact-modal-content {
    background-color: #fff;
    margin: 5% auto;
    /* border: 1px solid #888; */
    box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.16), 0 2px 10px 0 rgba(0, 0, 0, 0.12);
    width: 465px;
    height: auto;
    border-radius: 5px;
}

.import-contact-modal .importcontact-modal-header {
    /*background: #1D3152;*/
    padding: 25px;
}

.import-contact-modal .importcontact-modal-header h3 {
    font-family: "Montserrat", sans-serif;
    font-size: 20px;
    font-weight: 600;
    color: #344c60;
    margin: 0;
}

.importcontact-text {
    padding: 0 25px 35px;
}

/* The Close Button */
.import-contact-modal .close {
    color: #a9bbc6 !important;
    float: right;
    font-size: 36px;
    font-weight: 300;
    line-height: 0;
}

.import-contact-modal .close:hover,
.import-contact-modal .close:focus {
    color: black;
    text-decoration: none;
    cursor: pointer;
}

.import-contact-modal .importcontact-modal-content p {
    font-family: "Muli", sans-serif;
    font-size: 14px;
    font-weight: 400;
    color: #344c60;
    margin: 0;
}

.importcontact-modal-btn {
    border-radius: 22px;
    background: #1d3152;
    border: 0;
    color: #fff;
    font-family: "Muli", sans-serif;
    font-size: 12px;
    font-weight: 700;
    margin: 0 5px;
    padding: 7px 20px;
    text-decoration: none;
    display: inline-block;
}

.importcontact-modal-btn:hover {
    background: #a7b9c5;
    color: #fff;
    transition: all 0.3s;
}

.importcontact-modal-btn[disabled] {
    background: #a7b9c5;
    color: #fff;
    cursor: not-allowed;
}

.importcontact-btn {
    text-align: center;
    margin-top: 30px;
    cursor: pointer;
}

/* Import Contact Modal ends here */

/* New Contact Modal starts here */

.contacts-newcontact-modal {
    display: block;
    position: fixed;
    z-index: 1;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    overflow-y: scroll;
    /* background-color: rgb(0,0,0);
  background-color: rgba(0,0,0,0.4); */
}

/* Modal Content/Box */
.contacts-newcontact-modal .contacts-newcontact-modal-content {
    background-color: #fff;
    margin: 2% auto 0;
    border: 1px solid #888;
    width: 540px;
    border-radius: 5px;
    overflow: hidden;
}

/*brand  Modal Content/Box */
.brand-registration-modal .brand-registration-content {
    min-height: 794.444px;
    background-color: #fff;
    margin: 2% auto 0;
    border: 1px solid #888;
    width: 840px;
    border-radius: 5px;
    overflow: hidden;
}

.brand-registration-modal .brand-registration-modal-header {
    /*background: #1D3152;*/
    padding: 30px;
    position: relative;
}

.brand-registration-modal {
    display: block;
    position: fixed;
    z-index: 1;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    overflow-y: scroll;
    /* background-color: rgb(0,0,0);
  background-color: rgba(0,0,0,0.4); */
}

.brand-registration-modal .close {
    color: #1d3152 !important;
    float: right;
    font-size: 24px;
    font-weight: bold;
}

.brand-registration-modal .paragraph {
    font-family: "Montserrat", sans-serif;
    font-size: 15px;
    font-weight: 500;
    color: #000;
    margin: 0 0 15px 0px;
}

.brand-registration-modal .brand-registration-modal-header h3 {
    font-family: "Montserrat", sans-serif;
    font-size: 27px;
    font-weight: 500;
    color: #000;
    padding-bottom: 20px;
    margin: 0;
}

.brand-registration-modal span#contacts-newcontact-closebutton {
    position: absolute;
    right: 10px;
    font-weight: 400;
    top: 0px;
    color: #000 !important;
    font-size: 30px;
}

.contacts-newcontact-modal .contacts-newcontact-modal-header {
    /*background: #1D3152;*/
    padding: 30px;
}

.contacts-newcontact-modal .contacts-newcontact-modal-header h3 {
    font-family: "Montserrat", sans-serif;
    font-size: 20px;
    font-weight: 600;
    color: #344c60;
    margin: 0;
}

/* The Close Button */
.contacts-newcontact-modal .close {
    color: #1d3152 !important;
    float: right;
    font-size: 24px;
    font-weight: bold;
}

.contacts-newcontact-modal .close:hover,
.contacts-newcontact-modal .close:focus {
    color: black;
    text-decoration: none;
    cursor: pointer;
}

.contacts-newcontact-modal-btn {
    border-radius: 22px;
    background: #1d3152;
    border: 0;
    color: #fff;
    font-family: "Muli", sans-serif;
    font-size: 12px;
    font-weight: 700;
    margin: 0 5px;
    padding: 10px 20px;
    text-decoration: none;
    display: inline-block;
}

.contacts-newcontact-modal-btn:hover {
    background: #a7b9c5;
    color: #fff;
    transition: all 0.3s;
}

.contacts-newcontact-modal-cancel-btn {
    border-radius: 22px;
    background: #a7b9c5;
    border: 0;
    color: #fff;
    font-family: "Muli", sans-serif;
    font-size: 12px;
    font-weight: 700;
    margin: 0 5px;
    padding: 10px 20px;
    text-decoration: none;
    display: inline-block;
}

.contacts-newcontact-modal-cancel-btn:hover {
    background: #1d3152;
    color: #fff;
    transition: all 0.3s;
}

.contacts-newcontact-btn {
    text-align: right;
    margin-top: 30px;
}

.contacts-newcontact-form {
    padding: 30px 0 30px;
}

.contacts-newcontact-form label {
    /* color: #3AAF50; */
    color: #829285;
    font-family: "Montserrat", sans-serif;
    font-size: 12px;
    font-weight: 600;
    display: block;
    margin-bottom: 5px;
}

.contacts-newcontact-form input {
    width: 75%;
    border-radius: 5px;
    padding: 10px;
    border: 1px solid #c6d0d5;
}

/* .contacts-newcontact-form input:focus {
  border: 1px solid #37455E;
} */
.contacts-label {
    color: #3aaf50 !important;
}

.contacts-newcontact-form input::placeholder {
    color: #c6d0d5;
    font-family: "Muli", sans-serif;
    font-size: 12px;
    font-weight: 400;
}

.contacts-newcontact-form .form-group {
    margin-bottom: 20px;
    position: relative;
}

.tab-segment {
    border-bottom: 1px solid #c6d0d5;
    margin: 30px 0;
    padding-bottom: 25px;
}

.tab-segment h4 {
    color: #3aaf50;
    font-family: "Muli", sans-serif;
    font-size: 14px;
    font-weight: 600;
    margin: 10px 0 0;
}

.tab-segment p {
    color: #a7b9c5;
    font-family: "Muli", sans-serif;
    font-size: 14px;
    font-weight: 400;
    margin: 0;
}

.tab-segment-list ul {
    margin: 0;
    padding: 0;
}

.tab-segment-list ul li {
    list-style: none;
    display: inline-block;
    margin-right: 5px;
    margin-bottom: 10px;
}

.tab-segment-list ul li span {
    background: #f2f4f5;
    border-radius: 5px;
    border: 1px solid #d2dade;
    color: #a7b9c5;
    font-family: "Muli", sans-serif;
    font-size: 12px;
    font-weight: 400;
    padding: 6px 8px;
    display: inline-block;
}

.newcontact-buttons {
    text-align: right;
    margin-top: 100px;
}

.newcontact-reset-btn {
    background: transparent;
    border: 0;
    color: #a7b9c5;
    font-family: "Montserrat", sans-serif;
    font-size: 13px;
    font-weight: 400;
}

.newcontact-reset-btn:hover {
    color: #000;
    transition: all 0.3s;
}

.createcontact-btn {
    border: 1px solid #3aaf50;
    background: #3aaf50;
    color: #fff;
    font-family: "Montserrat", sans-serif;
    font-size: 13px;
    font-weight: 700;
    border-radius: 20px;
    padding: 10px 15px;
}

.createcontact-btn:hover {
    background: #fff;
    border: 1px solid #3aaf50;
    color: #3aaf50;
    transition: all 0.3s;
}

.error-strip {
    position: absolute;
    bottom: 100%;
    left: 4%;
    background: #eb5f5f;
    border-radius: 5px;
    display: inline-block;
    padding: 10px 35px;
    color: #fff;
    font-family: "Muli", sans-serif;
    font-size: 14px;
    font-weight: 400;
    text-align: center;
}

.error-strip:after {
    content: "";
    border-left: 15px solid transparent;
    border-right: 15px solid transparent;
    border-top: 10px solid #eb5f5f;
    position: absolute;
    bottom: -10px;
    margin: 0 auto;
    left: 0;
    right: 0;
    width: 0;
}

/* New Contact Modal ends here */

/* New Message Modal CSS starts here */

.contacts-newmessage-modal {
    display: flex;
    justify-content: center;
    align-items: center;
    position: fixed;
    z-index: 1;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    overflow: hidden;
    /* background-color: rgb(0,0,0);
    background-color: rgba(0,0,0,0.4); */
    /*overflow: hidden;*/
}

/* Modal Content/Box */
.contacts-newmessage-modal .modal-content {
    background-color: #fefefe;
    margin: 5% auto 0;
    border: 1px solid #888;
    border-radius: 5px;
    width: 708px;
    height: auto;
}

.contacts-newmessage-modal .modal-header {
    padding: 20px;
}

/* The Close Button */
.contacts-newmessage-modal .close {
    color: #aaa;
    float: right;
    font-size: 28px;
    font-weight: bold;
}

.contacts-newmessage-modal .close:hover,
.contacts-newmessage-modal .close:focus {
    color: black;
    text-decoration: none;
    cursor: pointer;
}

.contacts-newmessage-modal .newmessage-modals h3 {
    color: #344c60;
    font-family: "Montserrat", sans-serif;
    font-size: 22px;
    font-weight: 700;
    margin: 0;
}

.newmessage-modal-form {
    padding: 15px 0;
}

.newmessage-modal-form textarea {
    color: #344c60;
    font-family: "Muli", sans-serif;
    font-size: 12px;
    font-weight: 400;
    padding: 20px 15px;
    border: 1px solid #d8dbdd;
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
    width: 100%;
}

.contacts-newmessage-modal .setting-tab-content {
    padding: 20px;
}

.newmessage-modal-form textarea::placeholder {
    color: #c6d0d5;
    font-family: "Muli", sans-serif;
    font-size: 14px;
    font-weight: 400;
}

/* .newmessage-modal-form textarea:focus {
  border: 1px solid #3aaf50;
} */

.newmessage-modal-form label {
    color: #a7b9c5;
    font-family: "Montserrat", sans-serif;
    font-size: 12px;
    font-weight: 600;
    display: block;
    margin-bottom: 5px;
}

.newmessage-modal-form input {
    width: 100% !important;
    border: 1px solid #d2dade;
    border-radius: 5px;
    padding: 10px;
}

/* .newmessage-modal-form input:focus {
  outline: none;
  box-shadow: inset 0 0 1px rgba(58, 175, 80, 1), 0 0 0px 1px rgba(58, 175, 80, 1);
} */

.newmessage-modal-form input:focus label {
    color: #3aaf50;
}

.newmessage-modal-form input::placeholder {
    color: #c6d0d5;
    font-family: "Muli", sans-serif;
    font-size: 12px;
    font-weight: 400;
}

.newmessage-modal-form .total-characters {
    text-align: right;
}

.newmessage-modal-form .total-characters span {
    color: #a7b9c5;
    font-family: "Muli", sans-serif;
    font-size: 10px;
    font-weight: 400;
}

/* New Message Modal CSS ends here */

/* Export Modal CSS starts here */

.reports-export-modal {
    display: flex;
    justify-content: center;
    align-items: center;
}

/* Modal Content/Box */
.reports-export-modal .modal-content {
    background-color: #fefefe;
    margin: 5% auto 0;
    border: 1px solid #888;
    border-radius: 5px;
    width: 500px;
    height: auto;
}

.reports-export-modal .modal-header {
    padding: 30px;
}

.reports-export-modal .modal-content .export-modal-form {
    padding: 30px;
}

.reports-export-modal .modal-content .export-modal-form input {
    width: 100%;
    border-radius: 3px;
    padding: 10px;
    margin-top: 25px;
    border: 1px solid #c6d0d5;
}

/* .reports-export-modal .modal-content .export-modal-form input:focus{
  border: 1px solid #3AAF50;
  outline:none;
  box-shadow: none;
} */
.error-email {
    border: 1px solid red !important;
}

.reports-export-modal .modal-content .export-modal-form button {
    float: right;
    padding: 10px 35px;
    background: #1d3152;
    color: #fff;
    font-family: "Montserrat", sans-serif;
    font-size: 12px;
    font-weight: 700;
    text-transform: capitalize;
    border: 1px solid #1d3152;
    border-radius: 20px;
    margin: 30px 0;
}

.reports-export-modal .modal-content .export-modal-form button:hover {
    border: 1px solid #1d3152;
    background: transparent;
    transition: all 0.3s;
    color: #1d3152;
}

/* The Close Button */
.reports-export-modal .close {
    color: #a7b9c5;
    float: right;
    font-size: 36px;
    font-weight: 400;
}

.reports-export-modal .close:hover,
.reports-export-modal .close:focus {
    color: black;
    text-decoration: none;
    cursor: pointer;
}

.reports-export-modal .modal-header h2 {
    color: #344c60;
    font-family: "Montserrat", sans-serif;
    font-size: 20px;
    font-weight: 600;
    margin: 0;
    display: block;
}

.reports-export-modal .export-modals h3 {
    color: #344c60;
    font-family: "Montserrat", sans-serif;
    font-size: 16px;
    font-weight: 500;
    margin: 0;
    display: block;
}

/* Export Modal CSS ends here */

/* Reports page Sidebar section starts here */

.main-content {
    position: relative;
}

.main-content .reports-sidebar-content {
    position: absolute;
    top: 0;

    background: #fff;
    width: 30%;
    height: 100vh;
    border-left: 1px solid #d2dade;
    overflow: hidden;
}

.reports-sidebar-content .sidebar-closebtn {
    float: right;
    margin: 20px;
}

.reports-sidebar-heading h4 {
    color: #344c60;
    font-family: "Montserrat", sans-serif;
    font-size: 20px;
    font-weight: 700;
    margin: 0 0 8px;
}

.reports-sidebar-heading {
    padding: 20px;
    border-bottom: 1px solid #d2dade;
}

.reports-sidebar-heading p {
    color: #a7b9c5;
    font-family: "Muli", sans-serif;
    font-size: 12px;
    font-weight: 400;
    margin: 0;
}

.reports-sidebar-heading p span {
    color: #3aaf50;
    margin-right: 5px;
    font-weight: 700;
}

.engagement-status {
    padding: 20px;
    border-bottom: 1px solid #d2dade;
}

.engagement-status h4 {
    color: #344c60;
    font-family: "Montserrat", sans-serif;
    font-size: 14px;
    font-weight: 600;
    margin: 0;
}

.engagement-status label {
    color: #3aaf50;
    font-family: "Muli", sans-serif;
    font-size: 12px;
    font-weight: 700;
    margin: 0;
    float: right;
}

.engagement-status label span {
    color: #c6d0d5;
}

.engagement-status .progressbar {
    width: 100%;
    background: #fff;
    border-radius: 5px;
    margin-top: 20px;
}

.engagement-status .progressbar .leftbar {
    background: #3aaf50;
    border-radius: 5px;
    color: #fff;
    font-family: "Montserrat", sans-serif;
    font-size: 10px;
    font-weight: 600;
    text-align: center;
    padding: 7px 0;
    margin-right: 5px;
}

.engagement-status .progressbar .rightbar {
    float: right;
    background: #c6d0d5;
    border-radius: 5px;
    color: #fff;
    font-family: "Montserrat", sans-serif;
    font-size: 10px;
    font-weight: 600;
    text-align: center;
    padding: 7px 0;
    margin-right: -7px;
}

.search-recipients {
    background: #f2f4f5;
    padding: 7px 18px;
    border-bottom: 1px solid #d2dade;
}

/* .search-recipients button {
  padding: 5px 10px;
  border-radius: 20px;
  background: #C6D0D5;
  color: #fff;
  font-family: 'Montserrat', sans-serif;
  font-size: 10px;
  font-weight: 700;
  text-align: center;
  float: right;
  padding: 10px 15px;
  border: 1px solid #C6D0D5;
} */

.search-message-active {
    background: #3aaf50 !important;
    border: 1px solid #3aaf50 !important;
    color: #fff !important;
}

.search-message-delete-active {
    background: #e94d4d !important;
    border: 1px solid #e94d4d !important;
    color: #fff !important;
    cursor: pointer;
}

.search-recipients button:hover {
    background: #3aaf50;
    border: 1px solid #3aaf50;
    color: #fff;
    transition: all 0.3s;
}

.search-recipients input {
    width: 100%;
    border: 1px solid #d2dade;
    border-radius: 5px;
    background: #fff;
    padding: 10px;
}

.search-recipients input::placeholder {
    color: #c6d0d5;
    font-family: "Muli", sans-serif;
    font-size: 12px;
    font-weight: 400;
}

.reports-contacts {
    padding: 20px;
    background: #fff;
}

.reports-contacts-items .contact-items-heading h4 {
    color: #344c60;
    font-family: "Montserrat", sans-serif;
    font-size: 14px;
    font-weight: 600;
    margin: 0;
}

.reports-contacts-items {
    border: 1px solid #d2dade;
    border-radius: 5px;
}

.reports-contacts-items table {
    width: 100%;
    border-collapse: collapse;
}

.reports-contacts-items table td,
.reports-contacts-items table th {
    border-bottom: 1px solid #d2dade;
    padding: 10px;
}

.reports-contacts-items table tr:hover {
    background: #eeeff0;
    transition: all 0.3s;
}

.reports-contacts-items table td span {
    color: #344c60;
    font-family: "Muli", sans-serif;
    font-size: 10px;
    font-weight: 600;
}

.reports-contacts-items table td label {
    color: #a7b9c5;
    font-family: "Muli", sans-serif;
    font-size: 10px;
    font-weight: 600;
}

.reports-list-checkbox input {
    display: none;
}

.reports-list-checkbox .active-items:checked + label {
    background-color: #1d3152;
    border: 2px solid #1d3152;
    color: #99a1a7;
}

.reports-list-checkbox .active-items + label {
    border: 2px solid transparent;
    padding: 6px;
    border-radius: 3px;
    display: inline-block;
    position: relative;
}

.reports-contacts-items table tr:hover .reports-list-checkbox .active-items + label {
    background: #fff;
}

.reports-contacts-items table tr:hover .reports-list-checkbox .active-items:checked + label {
    background: #1d3152;
}

.reports-list-checkbox .active-items:checked + label:after {
    content: "\2714";
    font-size: 12px;
    position: absolute;
    top: -2px;
    left: 0px;
    color: #fff;
}

.reports-contacts-items-button {
    text-align: center;
    margin-top: 30px;
}

.reports-contacts-items-button button:first-child {
    border-radius: 20px;
    background: transparent;
    border: 1px solid #1d3152;
    color: #1d3152;
    font-family: "Montserrat", sans-serif;
    font-size: 12px;
    font-weight: 700;
    text-align: center;
    padding: 10px 20px;
    margin-right: 20px;
}

.reports-contacts-items-button button:first-child:hover {
    border-radius: 20px;
    background: #1d3152;
    border: 1px solid #1d3152;
    color: #fff;
    font-family: "Montserrat", sans-serif;
    font-size: 12px;
    font-weight: 700;
    text-align: center;
    padding: 10px 20px;
}

.export-button-class {
    border-radius: 20px;
    background: transparent;
    border: 1px solid #1d3152;
    color: #1d3152;
    font-family: "Montserrat", sans-serif;
    font-size: 12px;
    font-weight: 700;
    text-align: center;
    padding: 10px 20px;
    margin-right: 20px;
}

.export-button-class:hover {
    border-radius: 20px;
    background: #1d3152;
    border: 1px solid #1d3152;
    color: #fff;
    font-family: "Montserrat", sans-serif;
    font-size: 12px;
    font-weight: 700;
    text-align: center;
    padding: 10px 20px;
}

.react-multi-email > input:focus {
    box-shadow: none !important;
}

.reports-contacts-items-button button:last-child {
    border-radius: 20px;
    background: transparent;
    border: 1px solid #c6d0d5;
    color: #c6d0d5;
    font-family: "Montserrat", sans-serif;
    font-size: 12px;
    font-weight: 700;
    text-align: center;
    padding: 10px 20px;
}

.reports-contacts-items-button button:last-child:hover {
    background: #3aaf50;
    border: 1px solid #3aaf50;
    color: #fff;
    transition: all 0.3s linear;
}

/* Reports page Sidebar section ends here */

/* MY CSS */
.search-icon {
    position: absolute;
    left: 11px;
    top: 33%;
}

/* .search-field-wrapper form{
  position: relative;
} */
.search-field-wrapper form img {
    position: absolute;
    right: 10px;
    top: 30%;
    /* padding: 14px 9px; */
    cursor: pointer;
}

/* .middle-chat-content{
  overflow-y: auto;
} */

.inbox-chatbox-body {
    position: relative;
    /* overflow-y: hidden; */
    height: calc(100vh - 10.198rem);
    /* height: 87vh; */
    border-top: 1px solid #d2dade;
}

.inbox-chatbox-body-min-height {
    position: relative;
    /* overflow-y: hidden; */
    height: calc(100vh - 12.625rem);
    /* height: 87vh; */
    border-top: 1px solid #d2dade;
}

.inbox-container {
    height: calc(100vh - 88.95px);
    overflow: hidden;
}

.image-box-value {
    background: #324368;
    width: 32%;
    text-align: left;
    color: #fff;
    border-radius: 2.5px;
    padding: 7px;
}

.emoji-mart {
    left: 0px !important;
}

.inbox-chatbox-sent-message-container {
    padding: 8px;
}

.inbox-chatbox-received-message-container {
    padding: 8px;
}

/*{  MY CSS - ALI HUNAIN -NEW
}*/
.all-contacts-table-wrapper {
    overflow: auto;
    height: calc(100vh - 13.5rem);
    /* min-height: 400px; */
}

.action-button-dropdown {
    z-index: 1;
}

.hide {
    display: none;
}

.z-index-10 {
    z-index: 10;
}

.aside-menu-unread-message {
    top: -9px;
    position: absolute;
    left: -15px;
}

.aside-menu-unread-message span {
    background: #f73900;
    border-radius: 50%;
    padding: 6px 4px;
    margin-left: 27px;
    font-size: 7px !important;
    line-height: 0;
    display: inline-block;
}

/* ========
    Modal
===========*/

.back-drop {
    background-color: rgba(0, 0, 0, 0.7);
    height: 100%;
    position: fixed;
    left: 0;
    top: 0;
    transition: all 1.3s;
    width: 100%;
    z-index: 9999;
}

.modal-wrapper {
    position: relative;
    background: white;
    border: 1px solid #d0cccc;
    border-radius: 5px;
    box-shadow: 0 5px 8px 0 rgba(0, 0, 0, 0.2), 0 7px 20px 0 rgba(0, 0, 0, 0.17);
    margin: 110px auto 0;
    transition: all 0.8s;
    width: 700px;
}

.modal-header {
    height: 40px;
    line-height: 40px;
    padding: 5px 20px;
    /* text-align: right; */
}

.modal-header h1 {
    color: black;
    float: left;
    margin: 0;
    padding: 0;
}

.modal-body {
    padding: 10px 15px;
    /* text-align: center; */
}

.modal-footer {
    height: 35px;
    padding: 15px;
}

.close-modal-btn {
    color: gray;
    position: absolute;
    cursor: pointer;
    top: 0;
    text-decoration: none;
    font-size: 30px;
    margin: 0;
    right: 10px;
}

/* .firstBlur {
  -webkit-filter: blur(2px);
} */
.modal-overlay {
    position: fixed;
    top: 0px;
    left: 0px;
    right: 0px;
    bottom: 0px;
    box-shadow: 0 0 black;
}

/* .modal-content{
    position: absolute;
    left: 20%;
    border: 1px solid rgb(204, 204, 204);
    background: rgb(255, 255, 255);
    overflow: auto;
    border-radius: 4px;
    outline: none;
    padding: 20px;
    width: 40%;
    margin: 10%;
    display: inline;
} */
.inbox-message-unselected-overlay {
    background: url(./assets/Newimages/article-bg.png);
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    height: 100%;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.padding-top-10 {
    padding-top: 10px;
}

.blur-effect {
    position: fixed;
    width: 100%;
    height: 100%;
    filter: blur(3px);
}

/* css for serach box send new message */
.steps-container {
    border-radius: 5px;
    background-color: #ffffff;
}

.steps-container .steps-header {
    height: 4.125em;
    border-radius: 5px 5px 0 0;
    background-color: #fafafa;
}

.steps-container .steps-header .steps-header-content {
    padding: 1.0625em 1.125em;
    display: flex;
}

.steps-container .steps-header .steps-header-content img {
    margin-right: 1.1875em;
}

.steps-container .steps-header .steps-header-content h1 {
    color: #303030;
    font-family: "Montserrat";
    font-size: 1.875rem;
    line-height: 2.25rem;
    font-weight: 400;
}

.steps-container .step-one-content {
    padding: 1.5em 4.5em;
}

.step-one-content.flex-col {
    flex-direction: column;
    display: none;
}

.steps-container .step-one-content {
    display: flex;
}

.steps-container .step-two-content {
    min-height: 15.3125em;
    display: flex;
}

.steps-container .step-two-content {
    padding: 2.625em 0 2.625em 3em;
}

.step-one-right-content {
    margin: 2.5em 0 0 0;
    flex-basis: 51%;
    max-height: 200px;
    overflow-y: auto;
}

.step-one-right-content .selected-contacts {
    display: flex;
    align-items: center;
    border-radius: 4px;
    background-color: rgba(36, 107, 71, 0.66);
    padding: 0.625em;
    width: 12.375em;
    margin: 0 0.9375em 0.9375em 0;
}

.step-one-right-content .selected-contacts.segment {
    background-color: #5d6e9b;
}

.step-one-right-content .selected-contacts.campaign {
    width: 16.5rem;
}

.step-one-right-content .selected-contacts-wrapper {
    display: flex;
    flex-wrap: wrap;
}

.step-one-right-content .selected-contacts > * {
    font-weight: 600;
    line-height: 1.1875em;
}

.step-one-right-content .selected-contacts .selected-contacts-text {
    color: #fff;
    font-family: "Montserrat";
    font-weight: 300;
    font-size: 1rem;
    line-height: 1.1875rem;
}

.selected-contacts-text .icon {
    display: inline-block;
    max-width: 19px;
    margin-right: 7px;
}

.selected-contacts-text .icon img {
    width: 100%;
    vertical-align: bottom;
    filter: brightness(0) invert(1);
}

.step-one-right-content .selected-contacts .selected-contacts-cross {
    margin-right: 10px;
    cursor: pointer;
}

.step-one-content .select-all-contacts span,
.step-one-content .select-all-contacts label {
    font-family: "Muli";
    font-size: 1.125rem;
    line-height: 1.278em;
    align-self: flex-start;
    user-select: none;
}

.select-all-contacts span.disabled {
    pointer-events: none;
    opacity: 0.5;
}

.step-one-content .select-all-contacts span:not(:last-child),
.step-one-content .select-all-contacts label:not(:last-child) {
    margin-right: 2.375rem;
}

.step-one-content .select-all-contacts .checkbox-wrapper {
    margin-right: 0.625rem;
}

.step-one-content .select-all-contacts .checkbox-wrapper .checkmark {
    box-shadow: none;
}

.step-one-content .sms-campaign-contact-search-container {
    margin-top: 0.9375em;
}

.sms-campaign-contact-search-container.disabled {
    opacity: 0.5;
    pointer-events: none;
}

.sms-campaign-contact-search-wrapper input {
    width: calc(100%);
    /* box-shadow: 0 0 0px 1px #D2DADE */
}

.sms-campaign-contact-search-wrapper input:focus {
    box-shadow: none !important;
}

.step-one-content .sms-campaign-contact-search {
    border: 0;
    font-family: "Montserrat";
    font-weight: bold;
    font-size: 1.5625rem;
    line-height: 1.2em;
    border-bottom: 2px solid #000000;
}

.sms-campaign-contact-search-container .contact-search-dropdown {
    /* border-radius: 7px;
  background-color: #ffffff;
  box-shadow: 0px 7px 17px 0px #aaaaaa;
  max-height: 8.6875em;
  margin-top: 0.3125rem;
  overflow-y: auto;
  z-index: 99999;
  position: absolute;
  width: 100%;
  left: 0; */
    border-radius: 4px;
    background-color: #ffffff;
    /* box-shadow: 0px 7px 17px 0px #aaaaaa; */
    max-height: 8.6875em;
    /* margin-top: 0.3125rem; */
    overflow-y: auto;
    z-index: 99999;
    position: absolute;
    width: 100%;
    left: 0.5px;
    border: 1px solid #d8dbdd;
}

.sms-campaign-contact-search-container .contact-search-dropdown ul {
    list-style: none;
    padding: 0;
    margin: 0;
}

.sms-campaign-contact-search-container .contact-search-dropdown ul li {
    padding: 0.857em 0.857em 0 0.857em;
    cursor: pointer;
    color: #858181;
    /* font-family: "Muli"; */
    font-size: 0.69rem;
    line-height: 1.285em;
    display: flex;
}

.sms-campaign-contact-search-container
    .contact-search-dropdown
    ul
    li.contact-suggestions-list-item:hover {
    background-color: #c3e7ca;
}

.contact-suggestions-list-item {
    user-select: none;
}

.sms-campaign-contact-search-container .contact-search-dropdown ul li:last-child {
    margin-bottom: 0.857em;
}

.no-select {
    -webkit-touch-callout: none;
    /* iOS Safari */
    -webkit-user-select: none;
    /* Safari */
    -khtml-user-select: none;
    /* Konqueror HTML */
    -moz-user-select: none;
    /* Old versions of Firefox */
    -ms-user-select: none;
    /* Internet Explorer/Edge */
    user-select: none;
    /* Non-prefixed version, currently  */
}

/* NEW CSS  // MARCH */
.cross-img {
    min-width: "30px !important";
    float: "right";
    margin-right: "5px";
}

.image-box-container {
    display: flex;
    justify-content: space-between;
}

.image-gallery-icon {
    width: 23px;
    margin-right: 7px;
}

.image-cross-icon {
    width: 10px;
    margin-right: 8px;
}

.image-left-container {
    display: flex;
    font-size: 13px;
    align-items: center;
}

.image-box-textarea-chat {
    padding: 10px;
    background-color: #f2f4f5;
    border: 1px solid #d8dbdd;
}

.text-area-alignment {
    margin-bottom: -5px;
}

.heading-dot-p {
    font-family: "Montserrat", sans-serif !important;
    color: #1d3152 !important;
    font-size: 12px !important;
    font-weight: 600 !important;
    padding-left: 5px !important;
}

/* NEW CSS  // END MARCH */
.steps-container .step-one-content {
    padding: 1.5em 4.5em;
}

.step-one-content.flex-col {
    flex-direction: column;
}

.steps-container .step-one-content {
    display: flex;
}

.cursor-pointer {
    cursor: pointer;
}

.No-More-Feed {
    text-align: center;
    color: #d8dfe2;
    font-size: small;
}

/* Contact Alert Modal */

.contact-alert-modal {
    display: block;
    position: fixed;
    z-index: 1;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    overflow: hidden;
}

.ReactModal__Content.ReactModal__Content--after-open.modal-content {
    outline: none;
}

.contact-alert-modal .contact-alert-modal-content {
    background-color: #fff;
    margin: 15% auto 0;
    /* border: 1px solid #888; */
    width: 464px;
    height: auto;
    border-radius: 15px;
    text-align: center;
    box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.16), 0 2px 10px 0 rgba(0, 0, 0, 0.12);
}

.contact-alert-modal .contact-alert-modal-content img {
    margin: 18px auto;
}

.contact-alert-modal .contact-alert-modal-content.green {
    border-top: 20px solid #4eb762;
}

.contact-alert-modal .contact-alert-modal-content.red {
    border-top: 20px solid #eb5f5f;
}

.alertclosebutton {
    font-family: "Montserrat", sans-serif;
    font-size: 14px;
    font-weight: 500;
    color: #638ccf;
    text-align: center;
    display: block;
    padding-bottom: 20px;
    text-decoration: underline;
    border: 0;
    background: transparent;
    margin: 0 auto;
}

.three_dots {
    display: inline-block;
    vertical-align: text-bottom;
    position: relative;
}

/* .three_dots span {
  font-size: 20px;
  font-weight: 700;
} */

.three_dots .three_dots_dropdown {
    display: none;
    position: absolute;
    top: 25px;
    right: 0;
    background: #fff;
    border-radius: 5px;
    border: 1px solid #d2dade;
    padding: 8px 10px;
    z-index: 1;
    width: 85px;
}

.three_dots:hover {
    cursor: pointer;
}

.three_dots:hover .three_dots_dropdown {
    display: block;
}

.three_dots .three_dots_dropdown p {
    margin: 0;
}

.three_dots .three_dots_dropdown span {
    color: #1d3152;
    font-size: 10px;
    font-family: "Muli", sans-serif;
    font-weight: 400;
}

.Error-border-red {
    border: 1px solid red !important;
}

.react-autosuggest__suggestions-list li {
    width: initial !important;
    cursor: pointer;
}

.react-autosuggest__suggestions-list li:hover {
    background: transparent !important;
}

/*Scrollbar Designing starts here*/
/* width */
.chats-aside-content .tab-content::-webkit-scrollbar,
.main-content .contact-aside-content::-webkit-scrollbar,
.main-content .contacts-sidebar-content::-webkit-scrollbar,
.main-content .inbox-chatbox-body::-webkit-scrollbar,
.main-content .middle-chat-content::-webkit-scrollbar,
.importcontactcsv-text .tabledata-listing::-webkit-scrollbar,
.all-contacts-table-wrapper::-webkit-scrollbar,
.reports-content::-webkit-scrollbar,
.campainDetailIsOpen::-webkit-scrollbar,
textarea::-webkit-scrollbar,
.scroll-styling::-webkit-scrollbar {
    width: 5px;
}

/* Track */
.chats-aside-content .tab-content::-webkit-scrollbar-track,
.main-content .contact-aside-content::-webkit-scrollbar-track,
.main-content .contacts-sidebar-content::-webkit-scrollbar-track,
.main-content .inbox-chatbox-body::-webkit-scrollbar-track,
.main-content .middle-chat-content::-webkit-scrollbar-track,
.importcontactcsv-text .tabledata-listing::-webkit-scrollbar-track,
.all-contacts-table-wrapper::-webkit-scrollbar-track,
.reports-content::-webkit-scrollbar-track,
.campainDetailIsOpen::-webkit-scrollbar-track,
textarea::-webkit-scrollbar-track,
.scroll-styling::-webkit-scrollbar-track {
    background: #fff;
    box-shadow: inset 0 0 5px grey;
    border-radius: 20px;
}

/* Handle */
.chats-aside-content .tab-content::-webkit-scrollbar-thumb,
.main-content .contact-aside-content::-webkit-scrollbar-thumb,
.main-content .contacts-sidebar-content::-webkit-scrollbar-thumb,
.main-content .inbox-chatbox-body::-webkit-scrollbar-thumb,
.main-content .middle-chat-content::-webkit-scrollbar-thumb,
.importcontactcsv-text .tabledata-listing::-webkit-scrollbar-thumb,
.all-contacts-table-wrapper::-webkit-scrollbar-thumb,
.reports-content::-webkit-scrollbar-thumb,
.campainDetailIsOpen::-webkit-scrollbar-thumb,
textarea::-webkit-scrollbar-thumb,
.scroll-styling::-webkit-scrollbar-thumb {
    background: #1d3152;
    border-radius: 20px;
}

/* Handle on hover */
.chats-aside-content .tab-content::-webkit-scrollbar-thumb:hover,
.main-content .contact-aside-content::-webkit-scrollbar-thumb:hover,
.main-content .contacts-sidebar-content::-webkit-scrollbar-thumb:hover,
.main-content .inbox-chatbox-body::-webkit-scrollbar-thumb:hover,
.main-content .middle-chat-content::-webkit-scrollbar-thumb:hover,
.importcontactcsv-text .tabledata-listing::-webkit-scrollbar-thumb:hover,
.all-contacts-table-wrapper::-webkit-scrollbar-thumb:hover,
.reports-content::-webkit-scrollbar-thumb:hover,
.campainDetailIsOpen::-webkit-scrollbar-thumb:hover,
.scroll-styling::-webkit-scrollbar-thumb:hover {
    background: #555;
    transition: all 0.3s linear;
}

/*Scrollbar Desgining ends here*/

/*Scrollbar Designing starts here*/
/* width */
.gifbox .gifemojis::-webkit-scrollbar {
    width: 5px;
    border-radius: 20px;
}

/* Track */
.gifbox .gifemojis::-webkit-scrollbar-track {
    background: #f1f1f1;
    box-shadow: inset 0 0 5px grey;
    border-radius: 20px;
}

/* Handle */
.gifbox .gifemojis::-webkit-scrollbar-thumb {
    background: #3aaf50;
    border-radius: 20px;
}

/* Handle on hover */
.gifbox .gifemojis::-webkit-scrollbar-thumb:hover {
    background: #555;
    transition: all 0.3s linear;
}

/*Scrollbar Desgining ends here*/

/*Scrollbar Designing starts here*/
/* width */
::-webkit-scrollbar {
    width: 10px;
}

/* Track */

/* Handle */
::-webkit-scrollbar-thumb {
    background: #888;
    border-radius: 10px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
    background: #555;
    transition: all 0.3s linear;
}

/*Scrollbar Desgining ends here*/
.cursor-pointer {
    cursor: pointer;
}

.focus-outline-remove {
    outline: none;
}

/* Login Screen CSS Starts here */
.login-screen {
    background: url(./assets/Newimages/article-bg.png);
    background-size: cover;
    background-position: center center;
    background-repeat: no-repeat;
    width: 100%;
    height: 100vh;
    display: table;
}

.login-screen .container {
    display: table-cell;
    height: 100%;
    vertical-align: middle;
}

.login-screen .login-box {
    width: 665px;
    height: auto;
    background: url(./assets/Newimages/login-bg.png);
    background-size: contain;
    background-position: center center;
    background-repeat: no-repeat;
    margin: 20px auto;
    border-radius: 10px;
    padding: 0 110px;
    height: 610px;
}

.login-logo {
    text-align: center;
    padding: 50px 0 30px;
}

.login-logo img {
    width: 115px;
}

.login-form h2 {
    display: block;
    color: #fff;
    font-family: "Montserrat", sans-serif;
    font-weight: 600;
    font-size: 30px;
    margin: 0 0 30px;
    text-align: center;
}

.login-form h5 {
    display: block;
    color: #fff;
    font-family: "Montserrat", sans-serif;
    font-weight: 300;
    font-size: 18px;
    margin-bottom: 30px;
    text-align: center;
}

.login-form label {
    display: block;
    color: #fff;
    font-family: "Montserrat", sans-serif;
    font-weight: 600;
    font-size: 12px;
    margin-bottom: 5px;
}

.login-form input {
    background-color: #fff;
    border-radius: 3px;
    border: 0;
    width: 100%;
    padding: 12px 15px;
    margin-bottom: 20px;
    font-family: "Montserrat", sans-serif;
    color: #344c60;
    font-weight: 700;
    font-size: 12px;
}

.red-border {
    border: 2px solid red;
}

.login-form input:focus {
    outline: none;
    box-shadow: none;
}

.p-relative {
    position: relative;
}

.login-form button {
    width: 100%;
    border-radius: 5px;
    border: 1px solid #606e86;
    background: transparent;
    padding: 10px 0;
}

.login-form button:hover {
    background: #2a8d3c;
    transition: all 0.3s;
    border: 1px solid #2a8d3c;
}

.login-form button:hover span {
    color: #fff;
}

.login-form button span {
    text-transform: capitalize;
    font-family: "Montserrat", sans-serif;
    font-weight: 500;
    font-size: 14px;
    color: #606e86;
}

.login-form button.valid {
    background: #3aaf50 !important;
    box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.16), 0 2px 10px 0 rgba(0, 0, 0, 0.12);
}

.login-form button.valid span {
    color: #fff !important;
}

.login-form a {
    font-family: "Montserrat", sans-serif;
    color: #fff;
    font-size: 12px;
    font-weight: 400;
    display: block;
    text-align: center;
    text-decoration: none;
    margin-top: 10px;
}

.login-form h2 strong {
    color: #ef4e45;
}

.login-form a:hover {
    color: #36a54e;
    text-decoration: none;
    transition: all 0.3s;
}

.login-footer-logo {
    text-align: center;
    margin-top: 175px;
}

.login-footer-logo img {
    width: 75px;
}

/* Login Screen CSS ends here */
/*Mobiles Devices*/
@media (max-width: 575px) {
    .login-screen .login-box {
        width: 100%;
        height: auto;
        margin: 0;
        padding: 0 20px;
        background-size: cover;
        background-position: top center;
    }

    .login-screen {
        padding: 10px;
    }

    .login-footer-logo {
        padding: 100px 0 50px;
        margin: 0;
    }
}

/*IPAD*/
@media (min-width: 768px) and (max-width: 991px) {
    .login-screen .login-box {
        width: 100%;
        height: auto;
    }

    .login-footer-logo {
        padding: 100px 0 50px;
        margin: 0;
    }
}

/*IPAD PRO*/
@media (min-width: 992px) and (max-width: 1199px) {
}

/*Large Devices*/
@media (min-width: 1920px) {
}

.setting-tab-right-section {
    padding-top: 60px;
    background: #f4f6f7;
    height: 100%;
}

.setting-tab-right-section .mobile-phone {
    background: url(./assets/Newimages/mobile.png);
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center top;
    width: 250px;
    height: 500px !important;
    margin: 0 auto;
    padding: 30px;
    overflow: hidden;
}

.setting-tab-right-section .mobile-phone .text-msg {
    background: #eeeff0;
    width: 157px;
    padding: 15px;
    margin: 15px auto;
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
    border-bottom-right-radius: 5px;
    position: relative;
}

.setting-tab-right-section .mobile-phone .text-msg img {
    width: 100%;
    position: absolute;
    top: 0;
    left: 0;
    border-radius: 5px;
}

.setting-tab-right-section .mobile-phone .text-msg::before {
    content: "";
    display: block;
    position: absolute;
    width: 0;
    height: 0;
    left: 0px;
    transform: rotate(30deg);
    bottom: -10px;
    border-left: 0 solid transparent;
    border-right: 20px solid transparent;
    border-top: 20px solid #eeeff0;
}

.setting-tab-right-section .mobile-phone .text-msg p {
    font-family: "Montserrat", sans-serif;
    color: #344c60;
    font-weight: 500;
    font-size: 12px;
    margin: 0;
}

.new-campaign-panebar,
.new-campaign-br-only {
    border-right: 1px solid #3aaf50;
    height: 100%;
    position: relative;
}

.new-campaign-panebar::after {
    content: "";
    position: absolute;
    right: -13px;
    top: 0;
    z-index: 1;
    width: 25px;
    height: 25px;
    background-color: #ebf7ed;
    border: 1px solid #3aaf50;
    border-radius: 50%;
    padding: 10px;
    background-repeat: no-repeat;
    background-position: center center;
}

.new-campaign-panebar span {
    font-family: "Montserrat", sans-serif;
    color: #3aaf50;
    font-size: 14px;
    font-weight: 600;
    display: inline-block;
}

.new-campaign-panebar-age-gated,
.new-campaign-br-only {
    border-right: 1px solid #3aaf50;
    height: 100%;
    position: relative;
}

.new-campaign-panebar-age-gated::after {
    /* content: "";
  position: absolute;
  right: -13px;
  top: 0;
  z-index: 1;
  width: 25px;
  height: 25px;
  background-color: #EBF7ED;
  border: 1px solid #3AAF50;
  border-radius: 50%;
  padding: 10px;
  background-repeat: no-repeat;
  background-position: center center; */
}

.new-campaign-panebar-age-gated span {
    font-family: "Montserrat", sans-serif;
    color: #3aaf50;
    font-size: 14px;
    font-weight: 600;
    display: inline-block;
}

.newcampaign-modal-form-right {
    padding-left: 50px;
}

.br-0 {
    border-right: 0 !important;
}

.red-border {
    border: 1px solid red !important;
}

.loaderCenter {
    width: 100%;
    text-align: center;
    height: 10%;
    margin: 15% 0%;
}

.center-item {
    display: flex;
    /* justify-content: center; */
}

.center-item td {
    border: 0 !important;
}

.width-29 {
    width: 29%;
}

.width-69 {
    width: 69%;
}

.reports-table-content tr {
    cursor: pointer;
}

.login-screen .container {
    display: table-cell;
    height: 100%;
    vertical-align: middle;
}

.login-screen .login-box {
    width: 665px;
    height: auto;
    background: url(./assets/Newimages/login-bg.png);
    background-size: contain;
    background-position: center center;
    background-repeat: no-repeat;
    margin: 20px auto;
    border-radius: 10px;
    padding: 0 110px;
    height: 610px;
}

.p-relative {
    position: relative;
}

.login-form .eye img {
    cursor: pointer;
    position: absolute;
    right: 15px;
    top: 15px;
    width: 20px;
}

/* .login-form button{	
    width: 100%;	
    border-radius: 5px;	
    border: 1px solid #606E86;	
     background: transparent;	 
    font-family: 'Montserrat', sans-serif;	
    font-weight: 400;	
    font-size: 14px;	
    color: #fff;
    padding: 12px 0;	
    margin-top: 15px;	
  }	
  .login-form button:hover{	
    background: #36a54e;	
    transition: all .3s;	
    border: 1px solid #36a54e;	
    color: #fff;	
  }	 */
.login-form a {
    font-family: "Montserrat", sans-serif;
    color: #fff;
    font-size: 12px;
    font-weight: 400;
    display: block;
    text-align: center;
    text-decoration: none;
    margin-top: 10px;
}

.login-form a:hover {
    color: #36a54e;
    text-decoration: none;
    transition: all 0.3s;
}

/* Login Screen CSS ends here */
/*Mobiles Devices*/
@media (max-width: 575px) {
    .login-screen .login-box {
        width: 100%;
        height: auto;
        margin: 0;
        padding: 0 20px;
        background-size: cover;
        background-position: top center;
    }

    .login-screen {
        padding: 10px;
    }

    .login-footer-logo {
        padding: 100px 0 50px;
        margin: 0;
    }
}

/*IPAD*/
@media (min-width: 768px) and (max-width: 991px) {
    .login-screen .login-box {
        width: 100%;
        height: auto;
    }

    .login-footer-logo {
        padding: 100px 0 50px;
        margin: 0;
    }
}

/*IPAD PRO*/
@media (min-width: 992px) and (max-width: 1199px) {
}

/*Large Devices*/
@media (min-width: 1920px) {
}

.setting-tab-right-section {
    padding-top: 30px;
    padding-bottom: 30px;
    background: #f4f6f7;
    height: 100%;
}

.setting-tab-right-section .mobile-phone {
    background: url(./assets/Newimages/mobile.png);
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center top;
    width: 250px;
    height: 430px;
    margin: 0 auto;
    padding: 30px;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
}

.setting-tab-right-section .mobile-phone .text-msg {
    background: #eeeff0;
    width: 157px;
    padding: 15px;
    margin: 8px auto;
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
    border-bottom-right-radius: 5px;
    position: relative;
    word-break: break-word;
}

.setting-tab-right-section .mobile-phone .text-msg img {
    width: 100%;
    position: absolute;
    top: 0;
    left: 0;
    border-radius: 5px;
}

.setting-tab-right-section .mobile-phone .text-msg::before {
    content: "";
    display: block;
    position: absolute;
    width: 0;
    height: 0;
    left: 0px;
    transform: rotate(30deg);
    bottom: -10px;
    border-left: 0 solid transparent;
    border-right: 20px solid transparent;
    border-top: 20px solid #eeeff0;
}

.setting-tab-right-section .mobile-phone .text-msg p {
    font-family: "Montserrat", sans-serif;
    color: #344c60;
    font-weight: 500;
    font-size: 12px;
    margin: 0;
}

.new-campaign-panebar,
.new-campaign-br-only {
    border-right: 1px solid #3aaf50;
    height: 100%;
    position: relative;
}

.new-campaign-panebar::after {
    content: "";
    position: absolute;
    right: -13px;
    top: 0;
    z-index: 1;
    width: 25px;
    height: 25px;
    background-color: #ebf7ed;
    border: 1px solid #3aaf50;
    border-radius: 50%;
    padding: 10px;
    /* background-image:url(./assets/Newimages/new-campaign-panebar-green-tick.png);	 */
    background-repeat: no-repeat;
    background-position: center center;
}

.new-campaign-panebar span {
    font-family: "Montserrat", sans-serif;
    color: #3aaf50;
    font-size: 14px;
    font-weight: 600;
    display: inline-block;
}

.newcampaign-modal-form-right {
    padding-left: 50px;
}

.br-0 {
    border-right: 0 !important;
}

/* mmr styling */
.tag-input-container {
    display: flex;
    border: 1px solid #d8dbdd;
    align-items: center;
    border-radius: 5px;
    position: relative;
    flex-wrap: wrap;
    padding: 2px 0px;
}

.tag-input-container .tag-container {
    display: flex;
    margin: 0 6px;
    flex-wrap: wrap;
}

.tag-inner-container {
    display: flex;
    margin: 0 5px;
    color: #858585;
    border: 1px solid #d8dbdd;
    height: 24px;
    padding: 0px 5px;
    border-radius: 5px;
    align-items: center;
    background-color: #f1f1f1;
    margin-bottom: 2px;
}

.tag-inner-container .tag-text {
    text-transform: uppercase;
    border-right: 1px solid #d8dbdd;
    padding: 0px 10px;
    font-size: 10px;
}

.tag-inner-container .tag-remove {
    padding: 0px 6px;
    font-size: 12px;
    padding-bottom: 3px;
    cursor: pointer;
}

.tag-input-container .input-container {
    width: 100%;
}

.search-dd-title-container {
    padding: 0 10px;
    color: #3aaf50;
    font-size: 0.525rem;
}

.search-dd-title-container p {
    margin-bottom: 0;
}

.new-campaign-panebar-request-reply {
    margin-bottom: -21px;
    top: -39px;
    position: relative;
}

.request-reply-toggle {
    /* display: flex;
  align-items: center; */
    margin-bottom: 5px;
}

.request-reply-toggle p {
    font-size: 12px;
    margin-right: 10px;
    color: #a7b9c5;
    font-weight: bold;
    margin: 0;
    display: inline-block;
}

/* .request-reply-toggle label {
  margin-bottom: 0px;
} */

.messagebox-emoji-section {
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    z-index: 2;
}

.autoresponse-emoji-section {
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    z-index: 2;
    margin-bottom: 10px;
}

.default-send-campaign-btn {
    margin-top: 20px;
}

.DoughnutShow {
    display: inital;
}

.DoughnutHide {
    display: none;
}

.react-datepicker-popper {
    z-index: 3 !important;
}

.react-datepicker-wrapper {
    border: 1px solid #1d3152 !important;
    width: 100%;
}

.react-datepicker__current-month,
.react-datepicker__day-name {
    color: #ffffff !important;
}

.react-datepicker__header,
.react-datepicker__day--keyboard-selected,
.react-datepicker__day--selected {
    background-color: #1d3152 !important;
}

.react-datepicker__navigation--next,
.react-datepicker__navigation--previous {
    border-left-color: #fff !important;
}

.react-datepicker-time__input {
    width: 120px !important;
}

.img-fluid-container {
    display: flex;
    justify-content: center;
}

.send-test-phone-number {
    display: flex;
    align-items: center;
}

.panebar-succes-status::after {
    background-image: url(./assets/Newimages/new-campaign-panebar-green-tick.png);
}

.Green-Text::after {
    background-image: url(./assets/Newimages/new-campaign-panebar-green-tick.png);
}

.AutoAddingHours {
    max-height: 24vh;
    overflow-y: auto;
}

.actionButtonTransformDown {
    transform: rotate(180deg);
    transition: 1s transform;
    width: 12px;
}

.actionButtonTransformUp {
    transition: 1s transform;
    width: 12px;
}

.segmentSelected {
    color: #333 !important;
}

.campaign-pagination-container {
    display: flex;
    justify-content: flex-end;
}

.highlight {
    background-color: #e9f6ec;
    padding: 0;
    border-radius: 2px;
}

input[name="phoneNumber"] {
    background-image: url(./assets/Newimages/us-flag.jpg);
    background-position: 3% center;
    background-repeat: no-repeat;
    padding-left: 45px;
}

.no-campaign-modal {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    height: 100vh;
}

.no-campaign-modal .create-new-campaign {
    font-family: "Montserrat", sans-serif;
    font-size: 12px;
    font-weight: 700;
    background: #3aaf50;
    border-radius: 20px;
    color: #fff;
    padding: 10px 25px;
    border: 0;
    cursor: pointer;
}

.table-scroll-loader {
    text-align: center;
}

.inbox-chatbox-body-loader {
    text-align: center;
}

.empty-reports-section {
    padding: 30px;
    display: flex;
    justify-content: center;
}

.text-sms-campaign-validation-error {
    color: red;
    font-size: 12px;
    margin-top: 0;
}

.newcampaign-modal-form-vertical-spacing {
    margin-bottom: 25px;
}

.reports-table-overlay {
    position: absolute;
    left: 0;
    top: 46px;
    width: 100%;
    height: 95%;
    background-color: #f9f9f9;
    display: flex;
    justify-content: center;
    align-items: flex-start;
    min-height: 100px;
}

.report-tabel-container {
    position: relative;
}

.report-spinner-container {
    display: flex;
    justify-content: center;
    height: 40%;
    align-items: center;
    padding: 40px;
    z-index: 1000;
}

.hover-component {
    position: relative;
}

.hover-component:hover .hover-component__no-hover {
    opacity: 0;
}

.hover-component:hover .hover-component__hover {
    opacity: 1;
}

.hover-component__hover {
    position: absolute;
    top: 0;
    right: 0;
    opacity: 0;
    font-size: 12px;
    cursor: pointer;
}

.hover-component__no-hover {
    opacity: 1;
}

.focused-campain::after {
    background-color: #ffff !important;
}

.Focused-Text::after {
    background-color: #ffff !important;
}

.grey-campain::after {
    background-color: #d2dade !important;
}

.Grey-Text::after {
    background-color: #d2dade !important;
}

.tag-input-container:focus {
    border: 1px solid #65c176 !important;
}

.tag-input-container-focus {
    border: 1px solid #65c176 !important;
}

.Grey-Text {
    color: #c5d0d5 !important;
    font-family: "Montserrat", sans-serif !important;
    font-weight: 500 !important;
}

.Green-Text {
    color: #1d3152 !important;
    font-family: "Montserrat", sans-serif !important;
    font-weight: 400 !important;
}

.Focused-Text {
    color: #1d3152 !important;
    font-family: "Montserrat", sans-serif !important;
    font-weight: 600 !important;
}

.margin-bottom-5 {
    margin-bottom: 5px;
}

.inbox-chatbox-sent-message-content {
    word-break: break-word;
    text-align: left;
    white-space: pre-wrap;
}

.server-notification-text {
    font-family: "Montserrat";
    font-size: 15px;
    font-weight: 500;
    display: block;
    position: relative;
}

.dot-notification-text {
    content: "";
    left: 0;
    height: 2px;
    top: 50%;
    width: 25%;
    border-width: 2px;
    background-image: url("./assets/Newimages/dotted_short_1.svg");
    background-repeat: no-repeat;
}

/* p.server-notification-text:before {
  content:'';
  position: absolute;
  border-top: 2px dotted #ccc;
  width: 17vw;
  left: 0;
  top: 50%;
}
p.server-notification-text:after {
  content:'';
  position: absolute;
  border-top: 2px dotted #ccc;
  width: 17vw;
  right: 0;
  top: 50%;
  z-index: 2;
} */
.inbox-chatbox-received-message-content {
    word-break: break-word;
    text-align: left;
    white-space: pre-wrap;
    font-weight: 500;
    font-family: "Montserrat";
}

.msgreplybtn {
    background: transparent;
    border: none;
    color: #a7b9c5;
    font-family: "Muli", sans-serif;
    font-size: 14px;
    font-weight: 500;
    text-transform: capitalize;
}

.msgreplybtn:hover {
    cursor: pointer;
    color: #3aaf50;
    transition: all 0.3s ease;
}

.float-right {
    float: right;
}

.mr-30 {
    margin-right: 30px;
}

.square-fill .is-active,
.square-fill a:first-of-type,
.square-fill a:last-of-type {
    background-color: #1d3152 !important;
}

.schedule-invalid {
    background: #c6d0d5;
    border: 1px solid #c6d0d5;
}

.text-msg-scroll {
    overflow-y: auto !important;
}

/* Custom css */

.chat-navigation .chat-menu,
.chat-navigation .chat-right-menu {
    margin: 0;
    display: flex;
    align-items: center;
}

.chat-navigation .chat-menu {
    padding-inline-start: 40px;
}

.chat-navigation .chat-menu li {
    list-style: none;
    display: inline-block;
    margin: 0 20px;
    vertical-align: middle;
    line-height: initial;
}

.chat-navigation .chat-menu a {
    display: inline-block;
    vertical-align: middle;
    line-height: initial;
}

.chat-navigation .chat-menu li {
    list-style: none;
    display: inline-block;
    margin: 0 20px;
    vertical-align: middle;
    line-height: initial;
}

.chat-navigation .chat-menu li ~ a img {
    width: 22px;
    display: inline-block;
    vertical-align: middle;
}

.chat-navigation .chat-menu a.active li span {
    color: #fff;
    display: inline-block;
    vertical-align: middle;
}

.chat-navigation .chat-right-menu li {
    list-style: none;
    display: flex;
    align-items: center;
}

.display-none-default {
    display: none !important;
}

.display-block-defualt {
    display: block !important;
}

.chat-navigation .chat-right-menu li button {
    font-family: Montserrat, sans-serif;
    font-size: 12px;
    font-weight: 700;
    background: #3aaf50;
    border-radius: 20px;
    color: #fff;
    padding: 10px 25px;
    border: 0;
}

.chat-navigation .chat-right-menu li span {
    display: inline-block;
    vertical-align: middle;
}

.profile-setting-btn {
    border-radius: 50%;
    width: 40px;
    height: 40px;
    display: inline-block;
    background-repeat: no-repeat;
    background-position: 50%;
    background-size: contain;
    margin: 0 15px;
    position: relative;
    top: 0;
    vertical-align: middle;
}

.chat-right-menu img {
    display: inline-block;
    vertical-align: middle;
}

.my-masonry-grid {
    display: -webkit-box;
    /* Not needed if autoprefixing */
    display: -ms-flexbox;
    /* Not needed if autoprefixing */
    display: flex;
    margin-left: -30px;
    /* gutter size offset */
    width: auto;
}

.my-masonry-grid_column {
    padding-left: 0px;
    /* gutter size */
    background-clip: padding-box;
}

/* Style your items */
.my-masonry-grid_column > div {
    /* change div to reference your elements you put in <Masonry> */
    background: grey;
    margin-bottom: 30px;
}

.gifImage {
    padding: 5px;
}

.sidebar-profile-action-container {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
}

.sidebar-profile-action-container img {
    width: 30px;
}

.siderbar-each-action-container {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    /* text-align: center; */
}

.sidebar-profile-action-container p {
    font-size: 12px;
    margin-top: 4px !important;
    margin-bottom: 30px !important;
}

.age-gated ul {
    list-style-type: none;
    display: flex;
    padding: 0px;
}

.age-gated ul li {
    display: flex;
    align-items: center;
    flex: 1;
}

.age-gated .flex-5 {
    flex: 5;
}

.age-gated ul li label {
    padding-left: 10px;
}

.age-gated ul li input {
    width: auto !important;
}

/* FORM CSS START */
.formStyle h6,
.formStyle h5 {
    font-size: 18px !important;
    margin: 0 0 16px 0;
    color: #242336 !important;
}

.formStyle input[type="text"],
.formStyle select,
.formStyle input.registration-birthday {
    font-size: 13px !important;
    font-family: "Montserrat";
    font-weight: 500 !important;
    padding: 0 0 5px 0 !important;
    outline: none !important;
    box-shadow: none !important;
    /* margin-bottom: 25px !IMPORTANT; */
}

.formStyle input[type="text"]::placeholder.option,
.formStyle select.option,
.formStyle input.registration-birthday.typed::placeholder {
    color: #b9b9b9 !important;
}

.formStyle span {
    font-size: 13px !important;
    font-weight: 600 !important;
    line-height: 19px;
}

.formStyle .checkbox-main {
    margin: 0 20px 0px 0;
}

.formStyle .main-checkbox-wrapper {
    margin-top: 0;
}

.selectSubsClas p {
    margin: 3px 0 25px 0;
    font-size: 13px !important;
    font-weight: 500 !important;
    line-height: 19px;
}

.selectSubsClas h5 {
    margin: 0;
}

.registration-button button[type="submit"] {
    background: #1e3251;
    height: 50px !important;
    width: 60% !important;
    border-radius: 30px !important;
    margin: 20px auto 0 auto;
    display: block;
    color: #fafafa;
    font-family: "Montserrat";
    font-size: 18px;
    font-weight: 600;
    line-height: 21px;
    border: none;
}

.selectRegister ul {
    padding: 0;
    display: flex;
    flex-wrap: wrap;
}

.selectRegister ul li {
    display: block;
    margin: 0 10px 10px 0;
    padding: 8px 15px;
    border-radius: 5px;
    color: #fff;
    background: #a8b9c5;
    font-family: "Montserrat";
    font-size: 15px;
    cursor: pointer;
}

.selectRegister ul .active {
    background: #1d3152;
}

.registration-main-container {
    flex-direction: column;
}

.registration-terms {
    margin: 1.5rem 0 0rem 0 !important;
}

.registration-main-wrapper {
    padding: 30px 20px 14px 20px !important;
}

.registration-terms {
    font-size: 10px;
    line-height: 15px;
}

.registration-checkbox-area .checkbox-container input[type="checkbox"]:checked + .tick-area {
    background: #1e3251 !important;
}

.registration-main-container .group input.registration-birthday {
    box-shadow: none !important;
}

/* FORM CSS END */

/* WEBCHAT CSS */

.access-webchat ul {
    list-style: none;
    display: flex;
    margin: 0;
    padding: 0;
}

.account-webchat {
    padding: 25px;
}

.access-webchat {
    margin-bottom: 30px;
}

.chats-autoresponder-modal .access-webchat h3 {
    font-size: 17px;
    margin-bottom: 15px;
}

/* .access-webchat ul li {
    padding-left: 30px !important;
} */
.checkbox-webAccess :checked {
    background: black;
    background-size: 20px 20px;
    background-position: 50% 50%;
}

.webAccess-grid {
    display: grid !important;
    grid-template-columns: repeat(3, 1fr);
    grid-gap: 0.5rem;
    width: 100%;
}
.webAccess-grid li input[type="checkbox"]:checked {
    accent-color: #3aaf50 !important;
    color: white;
}
.webAccess-grid li input[type="checkbox"]:checked::after {
    background-color: white;
}

.access-webchat ul li label {
    margin-right: 7px !important;
}

.webchat-table ul {
    list-style: none;
    padding: 0;
    margin: 0 0 40px;
    overflow-y: scroll;
    height: 96px;
}

.webchat-table li {
    display: flex;
    font-family: "Montserrat";
    padding: 15px 0px;
    font-size: 12px;
}

.webchat-table li span {
    width: 33.33%;
    word-break: break-all;
    padding: 0 15px;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
}

.webchat-table li:first-child {
    border-bottom: 1px solid #ccc;
}

.webchat-table li span button {
    border: 0;
    background: transparent;
    font-size: 12px;
    font-family: "Montserrat";
    font-weight: bold;
    color: #3aaf50;
}

.webchat-last .btngrps {
    display: flex;
    justify-content: center;
    gap: 20px;
    margin-top: 35px;
}

.webchat-last .btngrps button {
    background: #3aaf50;
    border-radius: 4px;
    border: 0;
    padding: 12px 20px;
    font-size: 14px;
    font-family: "Montserrat";
    font-weight: bold;
    color: #fff;
    cursor: pointer;
}

.webchat-last .btngrps .gethelp {
    background: #aabcc9;
}

.webchat-last .company-box {
    display: flex;
    flex-direction: column;
    border-radius: 4px;
    overflow: hidden;
    box-shadow: 0 0 10px rgb(0 0 0 / 10%);
}

.webchat-last .company-box .companyBody {
    min-height: 100px;
    background: #fff;
    display: flex;
    align-items: flex-end;
    justify-content: center;
    padding: 20px;
}

.webchat-last .company-box .companyBody button {
    border: 0;
    color: #fff;
    font-size: 12px;
    font-family: "Montserrat";
    font-weight: bold;
    padding: 10px 15px;
    border-radius: 20px;
    cursor: pointer;
}

.webchat-last .companyHeader {
    display: flex;
    align-items: center;
    padding: 17px 15px;
}

.webchat-last .companyHeader img {
    max-width: 40px;
    border-radius: 50px;
}

.webchat-last .companyHeader .clogo {
    margin-right: 15px;
}

.webchat-last .companyHeader h6 {
    margin: 0;
    color: #fff;
    font-size: 14px;
    font-family: "Montserrat";
    font-weight: bold;
}

.webchat-last .companyHeader p {
    margin: 3px 0px 0px;
    color: #fff;
    font-size: 12px;
    font-family: "Montserrat";
}

.chats-autoresponder-modal .webchat-last h3 {
    font-size: 17px;
}

.webchat-last p {
    margin: 30px 0px 7px;
    font-family: "Montserrat";
    font-size: 13px;
}

.webchat-last .radiobtn {
    margin: 0;
    padding: 0;
    list-style: none;
    display: flex;
    gap: 10px;
}

.webchat-last .radiobtn li {
    display: flex;
    align-items: center;
    position: relative;
}

.webchat-last .radiobtn label {
    font-size: 13px;
    font-family: "Montserrat";
}

.webchat-last .radiobtn li input {
    appearance: none;
    width: 14px;
    height: 14px;
    margin: 0 3px 0px 0px;
    z-index: 2;
}

.webchat-last .radiobtn li .check {
    width: 14px;
    height: 14px;
    position: absolute;
    left: 0;
    top: 0;
    z-index: 1;
    border: 2px solid #ccc;
    border-radius: 20px;
}

.webchat-last .radiobtn li .check:before {
    content: "";
    position: absolute;
    width: 8px;
    height: 8px;
    background: #1d3152;
    border-radius: 20px;
    left: 1px;
    top: 1px;
    opacity: 0;
}

.webchat-last .radiobtn li input:checked ~ .check:before {
    opacity: 1;
}

.webchat-last .colorlist {
    margin: 30px 0px 0px;
    padding: 0;
    list-style: none;
    display: flex;
    gap: 15px;
}

.webchat-last .colorlist li label {
    font-family: "Montserrat";
    font-size: 13px;
    margin-bottom: 10px;
    display: inline-block;
    font-weight: bold;
    color: #1d3152;
}

.colorlist .colorbox {
    display: flex;
    align-items: center;
    border: 1px solid #ccc;
    border-radius: 5px;
    padding: 3px 7px;
    width: max-content;
}

.colorlist .colorbox input:focus {
    box-shadow: 0 0 0 0 !important;
}

.colorlist .colorbox span {
    font-family: "Montserrat";
    font-weight: bold;
    color: #1d3152;
    font-size: 14px;
    margin-right: 13px;
}

.colorlist .colorbox input {
    border: none;
    background: transparent;
    padding: 0;
    width: 30px;
    height: 34px;
    border-radius: 15px;
    overflow: hidden;
}

.widgetListItemContainer {
    border-bottom: 1px solid #dde4e7;
    transition: 0.5s;
    cursor: pointer;
}

.isSelectedWidget {
    background-color: #f3f4f5;
}

.widgetListItemContainer:hover {
    background-color: #f3f4f5;
}

.account-webchat
    .access-webchat
    .active-list
    li:first-child
    .active-checkbox:checked
    + label:after {
    content: "\2714";
    font-size: 11px;
    position: absolute;
    top: -2px;
    left: 0px;
    color: #fff;
    width: initial;
    height: initial;
    border: 0;
    background: #1d3152;
}

.contact-aside-content .active-list li:not(:first-child) .active-items:checked + label:after {
    font-size: 11px;
}

.account-webchat .access-webchat .active-list li:first-child .active-checkbox:checked + label {
    background-color: #1d3152;
    border: 2px solid #1d3152;
    color: #99a1a7;
}

.access-webchat ul > li {
    font-size: 15px;
    display: flex;
    align-items: center;
    position: relative;
    padding-left: 15px;
}
.access-webchat ul > li input {
    position: absolute;
    margin: 0;
    left: -2px;
    top: 5px;
    appearance: none;
    width: 13px;
    height: 13px;
    cursor: pointer;
    z-index: 3;
    border-radius: 2px;
}

.access-webchat ul > li .cs-chck {
    width: 13px;
    height: 13px;
    position: absolute;
    left: -2px;
    top: 5px;
    z-index: 1;
    background: transparent;
    border: 1px solid #ccc;
    border-radius: 2px;
}

.access-webchat ul > li .cs-chck:after {
    content: "\2714";
    position: absolute;
    z-index: 2;
    font-size: 10px;
    color: #fff;
    left: 1.5px;
    top: -2px;
    opacity: 0;
}

.access-webchat ul > li input:checked + .cs-chck:after {
    opacity: 1;
}
.access-webchat ul > li input:checked + .cs-chck {
    background-color: #2a8d3c;
    border: 1px solid #2a8d3c;
}

.contact-aside-content .active-list li:not(:first-child) .active-items + label {
    padding: 5px;
}

.webchat-table .tableHeadings {
    background: #1d3152;
    display: flex;
    border-radius: 5px 5px 0 0;
    color: #fff;
    font-size: 17px;
    font-family: "Montserrat";
}

.webchat-table .tableHeadings span {
    width: 33.33%;
    padding: 20px 15px;
}

.webchat-table .tableHeadings span h5 {
    margin: 0;
}

.webchat-table ul::-webkit-scrollbar-thumb {
    background: #1d3152;
    border-radius: 20px;
}

.webchat-table ul::-webkit-scrollbar-track {
    background: #fff;
    box-shadow: inset 0 0 5px grey;
    border-radius: 20px;
}

.webchat-table ul::-webkit-scrollbar {
    width: 5px;
}

/* WEBCHAT CSS */

.dropdown-show ul li {
    padding: 10px;
}

/* fix for contacts table */
.contacts-table {
    width: calc(100vw - 320px) !important;
}
.contacts-table .ant-pagination {
    padding-right: 20px;
}

.contacts-table input:focus {
    box-shadow: none !important;
}

.importContact-segmented {
    margin-top: 18px;
    margin-bottom: 5px;
}

.importContact-segmented .ant-segmented-group label {
    margin-top: 0;
}

.upload-list-container {
    position: absolute;
    bottom: 0;
    right: 0;
    padding: 14px;
    max-width: 400px;
    width: 100%;
}
.upload-list-action-status {
    display: flex;
    justify-content: center;
    align-items: center;
}