/* utilities */
.flex-sb {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
}
.flex {
    display: flex;
}
.flex-col {
    flex-direction: column;
}
.flex-50 {
    width: 50%;
}
.flex-100 {
    width: 100%;
}
.inline-flex-center {
    display: inline-flex;
    justify-content: center;
    align-items: center;
}
.h-100 {
    height: 100vh;
}
.relative {
    position: relative;
}
.hidden {
    display: none;
}
.uppercase {
    text-transform: uppercase;
}
.primary-text {
    color: #3aaf50 !important;
}
.bolder {
    font-weight: bolder;
}
/* classes */
.account__left {
    background-image: url("../../assets/images/bg.jpg");
    background-size: cover;
}

.account__logo {
    font-size: 0;
    position: absolute;
    top: 3.125rem;
    left: 3.125rem;
}

.account__logo img {
    width: 250px;
}

.account__right {
    background-color: #fff;
    overflow: hidden;
    overflow-y: auto;
    height: 100vh;
}

.account__have {
    height: 4.375rem;
    display: inline-flex;
    justify-content: flex-end;
    align-items: center;
    padding: 0 3.125rem;
}

.account__have span {
    font-weight: 600;
    font-size: 1.125rem;
    font-family: "Montserrat", sans-serif;
}

.account__form {
    min-height: calc(100vh - 4.375rem);
}

.account__form-link {
    text-decoration: none;
    font-size: 1.2rem;
    font-weight: 800;
}

@media only screen and (max-width: 56.25em) {
    .sm-hidden {
        display: none;
    }
    .sm-flex-100 {
        width: 100% !important;
    }
    .sm-visible {
        display: initial;
    }
}

@media only screen and (max-width: 37.5em) {
    .xs-flex-col {
        flex-direction: column;
    }
}

@media only screen and (max-width: 87.5em) {
    .account__logo {
        top: 2.5rem;
        left: 2.5rem;
    }
    .account__logo img {
        width: 182px;
    }
    .account__have {
        font-size: 14px;
    }
}

@media only screen and (max-width: 56.25em) {
    .account__have {
        justify-content: space-between;
        height: 4.375rem;
    }
    .account__logo {
        position: relative;
        top: initial;
        left: initial;
        max-width: 12.5rem;
        display: inline-block !important;
    }
}

@media only screen and (max-width: 37.5em) {
    .account__form {
        min-height: calc(100vh - 7.5rem);
    }
    .account__have {
        justify-content: center;
        height: 7.5rem;
    }
    .account__logo {
        padding: 1.25rem 0;
    }
}
