.registration-success-main-container {
    box-sizing: border-box;
    padding: 3rem;
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;
    margin-top: 50%;
}

.registration-success-main-wrapper {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    max-width: 450px;
    padding: 28px 20px;
    border-radius: 3px;
    background-color: #fff;
    box-shadow: 0 0 4px 0 rgba(0, 0, 0, 0.25);
}

.registration-success-main-wrapper .registration-success-icon {
    max-width: 80px;
}

.registration-success-main-wrapper .registration-success-icon img {
    width: 100%;
}

.registration-success-main-wrapper .registration-success-heading {
    font-size: 21.6px;
    margin-top: 9.6px;
    font-family: "Montserrat";
    color: #303030;
    text-align: center;
}
