@tailwind base;
@tailwind components;
@tailwind utilities;

body {
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu",
        "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}

.st0 {
    fill: #00a850;
}
.st1 {
    fill: #e84d4e;
}
.st2 {
    fill: #ffffff;
}
.st3 {
    font-family: "MyriadPro-Regular";
}
.st4 {
    font-size: 9.3813px;
}
