.verify-container {
    width: 100vw;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
}

.verify-page-content {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.verify-page-loader {
    margin-bottom: 1rem;
}

.verify-page-text {
    color: #898989;
    font-size: 1.5rem;
    font-weight: 500;
}
