.registration-main-container {
    box-sizing: border-box;
    padding: 3rem 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    padding-bottom: 100px;
}

.registration-main-wrapper {
    width: 100%;
    max-width: 450px;
    /* padding: 28px 20px; */
    border-radius: 3px;
    background-color: #fafafa;
    box-shadow: 0 0 4px 0 rgb(0 0 0 / 25%);
    border: 20px solid #f1f1f1;
}

.registration-heading {
    display: flex;
    flex-direction: column;
    align-items: center;
}
.Confirm-information {
    font-family: "Montserrat" !important;
}

.registration-heading .heading-title {
    color: #303030;
    font-family: "Montserrat";
    font-size: 15px;
    font-weight: 600;
    letter-spacing: 1.3px;
    line-height: 15px;
    margin-bottom: 5px;
    text-transform: uppercase;
}

.registration-heading .heading-title.mb {
    margin-bottom: 26px;
}

.registration-checkbox-area .title {
    color: #737272;
    font-family: "Montserrat";
    font-size: 18px;
    font-weight: 700;
    line-height: 21px;
}

.registration-checkbox-area {
    margin-top: 45px;
}

.registration-checkbox-area .checkbox-container {
    width: 26px;
    height: 26px;
    display: inline-block;
    position: relative;
}

.registration-checkbox-area .checkbox-container input[type="checkbox"] {
    opacity: 0;
    width: 100%;
    height: 100%;
    position: relative;
    z-index: 10;
}

.registration-checkbox-area .checkbox-container input[type="checkbox"]:checked + .tick-area {
    background-color: #246b47;
    border: initial;
}

.registration-checkbox-area .tick-area {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    display: inline-block;
    border: 3px solid #737272;
    border-radius: 4px;
}

.registration-checkbox-area .checkbox-container input[type="checkbox"]:checked + .tick-area::after {
    content: "";
    position: absolute;
    display: block;
    top: 4px;
    left: 10px;
    width: 7px;
    height: 15px;
    border: solid #fff;
    border-width: 0 2px 2px 0;
    -webkit-transform: rotate(45deg);
    transform: rotate(45deg);
}

.checkbox-container + label {
    color: #737272;
    font-family: "Montserrat";
    font-size: 18px;
    font-weight: 700;
    line-height: 23px;
    margin-left: 9px;
}

.checkbox-main {
    display: flex;
    align-items: center;
    flex-basis: 50%;
    margin: 1.5rem 0;
}

.main-checkbox-wrapper {
    margin-top: 20px;
    display: flex;
    flex-wrap: wrap;
}

.registration-terms {
    color: #000;
    font-family: "Montserrat";
    font-size: 9px;
    line-height: 12px;
    text-align: center;
    margin: 2rem 0;
}

.registration-main-container .no-data-msg {
    color: #737272;
    font-family: "Montserrat";
    font-size: 11px;
    line-height: 21px;
    text-align: center;
    width: 100%;
    font-weight: 600;
    margin: 0;
}

.registration-main-container .error-msg {
    margin-top: 4px;
    color: #e62942;
    font-family: "Montserrat";
    font-size: 11px;
    font-weight: 600;
    line-height: 13px;
}

.registration-modal {
    max-width: 500px;
    width: 90%;
}

.registration-modal .alert-modal-wrapper img {
    width: 80px;
}

.registration-modal .alert-modal-wrapper .btn-wrapper {
    margin-top: 2rem;
}

.registration-main-container .group input.registration-birthday {
    color: #b9b9b9;
    font-family: Montserrat;
    font-size: 14px;
    font-weight: 600;
}
.registration-main-container .group input.registration-birthday.typed {
    color: #303030;
}

@media all and (min-width: 450px) {
    .checkbox-main {
        flex-basis: initial;
        margin: 1.5rem 1rem;
    }
}
